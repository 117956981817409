import React, {Component} from 'react';
import {Map as GoogleMap, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

export const getCityLocation = async (staffList, maps) => {
    // console.log(staffList);
    let bounds = [];
    let cityLocation = (city) => new Promise(async (resolve) => {
        let geocoder = new maps.Geocoder();

        let cityLatLng = geocoder.geocode(city).then(result => {
            return result.results.map(item => {return {
                lat: item.geometry.location.lat(),
                lng: item.geometry.location.lng(),
            }})[0];
        });

        resolve(cityLatLng);
    });

    let cities = Object.values(staffList).map(staff => {
        return {staff, name: Object.values(staff.deliveryServices).filter(item => item.isDefaultService)[0].city}
    });
    // console.log(cities);
    cities.forEach(city => {
        bounds.push(cityLocation({address: city.name}), city.staff);
    })

    return Promise.all(bounds).then(result => {
        let cities = [];
        result.forEach((item, i) => {
            // console.log(item);
            if(i % 2) cities.push({staff: {...item}, latlng: {...result[i - 1]}})
        })

        // console.log(cities);

        return cities;
    })
}

const getLocation = (staffList) => {
    let citiesList = new Map();

    return Object.values(staffList).map(location => {
        // console.log(location);
        let deliveryService = Object.values(location.deliveryServices).filter(deliveryService => deliveryService.isDefaultService)[0];
        citiesList[deliveryService.city] = deliveryService.latlng;

        if(citiesList.has(deliveryService.city)) {
            let shift = citiesList.get(deliveryService.city) + 0.01;
            console.log('shift staff location', deliveryService.city, shift);
            deliveryService.latlng.lng += shift
            citiesList.set(deliveryService.city, shift);
        } else {
            citiesList.set(deliveryService.city, 0);
        }

        return {
            id: location.id,
            firstName: location.firstName,
            lastName: location.lastName,
            cellNumber: Object.values(location.cellNumbers).filter(cellNumber => cellNumber.isDefault)[0].number,
            deliveryService,
        }
    })
}

export class StaffMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoad: false,
            centerMapLatLng: {
                lat: 49.4074864219669,
                lng: 32.0173968310712,
            },
            locations: getLocation(props.staffList),
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
        }

        this.onMarkerClick = this.onMarkerClick.bind(this);
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    async componentDidMount() {
        let locations = getLocation(await this.props.staffList);

        // console.log(locations);

        this.setState({locations});
    }

    render() {
        return (
            <GoogleMap
                google={this.props.google}
                zoom={6.3}
                style={{ height: 700 }}
                initialCenter={{...this.state.centerMapLatLng}}
            >
                {
                    this.state.locations.map(location => {
                        return <Marker
                            key={location.id}
                            position={location.deliveryService.latlng}
                            name={'Current location'}
                            title={`${location.firstName} ${location.lastName} ${location.cellNumber} ${location.deliveryService.city}`}
                            onClick={this.onMarkerClick}
                        />

                    })
                }

                <InfoWindow
                    style={{color: 'red'}}
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                >
                    <div className={'infoWindow'}>
                        <p>{this.state.activeMarker.title}</p>
                    </div>
                </InfoWindow>
            </GoogleMap>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyD0CoH32EDpVNWWlxs1qV_F7nEHGE18gZE")
})(StaffMap)
