import React, {useEffect, useState} from 'react';
import {Button, Loader, Icon, Segment, Dimmer, Modal, Table} from 'semantic-ui-react';
import {fetchOrderList} from "../request/fetchOrderList";
import {doneOrder} from "../request/doneOrder";
import { jsPDF } from "jspdf";
import {AmiriRegularTTF} from '../components/Amiri-Regular_ttf'
import {removeOrder} from "../request/removeOrder";

const styles = {
    newOrderContainer: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
    printAllOrders: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexWrap: "wrap",
        width: '100%',
        marginBottom: 20,
    },
    newOrder: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
    orderList: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
        // backgroundColor: '#ded7d7',
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 90 / 100,
    },
    temporaryPreviewPdf: {
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 95 / 100,
    },
    orderContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: "wrap",
        marginRight: 20
    },
    orderName: {
        marginRight: 10
    }
};

function OrderList(props) {
    const [open, setOpen] = React.useState(false)
    const [orderList, setOrderList] = useState({});
    const [order, setOrder] = useState({});
    const [isSpinner, setIsSpinner] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState();
    const [environment, setEnvironment] = useState({...props.environment});
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts:true
    });
    doc.addFileToVFS("Amiri-Regular.ttf", AmiriRegularTTF);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri");

    const createOrdersPdf = () => {
        Object.values(orderList)
            .filter(order => environment.series.season[0] === order.lessons.series.season[0] && environment.year === order.lessons.year)
            .reverse()
            .map((order, i) => {
                if(order.doneTime) return;

                if(i % 2 || i === 1) {
                    createOrderPdf(order.id, 148)
                    if(i !== 0) doc.addPage();
                } else {
                    createOrderPdf(order.id, 0)
                }
            })
    }
    const createOrderPdf =   (orderId, shift) => {
        let order = orderList[orderId];
        // if(order.doneTime) return;
        let setX = (x) => x + shift;

        setSelectedOrderId(orderId);

        //Personal and delivery section
        doc.setFontSize(14);
        doc.line(setX(148), 2, setX(148), 208, "DF")
        doc.line(setX(95), 2, setX(146), 45, "DF")
        doc.text(`${order.staff.deliveryService?.receiver?.firstName || 'Error: undefined'}`, setX(130),7, null, -42);
        doc.text(`${order.staff.deliveryService?.receiver?.lastName || 'Error: undefined'}`, setX(120), 8, null, -42);
        doc.setFontSize(18);
        doc.text(`${order.staff.deliveryService.name}`, setX(110),10, null, -42);
        doc.setFontSize(24);
        doc.text(`${order?.lessons?.series?.season[0] || ''}`, setX(5), 13);
        doc.text(`${order?.lessons?.year || ''} серія ${order?.lessons?.series?.currentSeries?.value || ''}`, setX(32), 13);
        doc.setFontSize(14);
        doc.text(`${order.staff.lastName}`, setX(10), 23);
        doc.text(`${order.staff.firstName}`, setX(10),31);
        doc.text(`${order.staff.middleName}`, setX(10),39);
        doc.text(`${order.staff.cellNumber}`, setX(10),47);
        doc.line(setX(53), 20, setX(53), 50, "DF")
        doc.setFontSize(18);
        doc.text(`${order.staff.deliveryService.name}`, setX(60),25);
        doc.text(
            `${order.staff.deliveryService.city } ${order.staff.deliveryService.department}`,
            setX(60),35, { maxWidth: 70 }
        );
        doc.text(`обл. ${order.staff.deliveryService.region }`, setX(60),50);
        doc.text(`Отримувач:`, setX(8),58);
        doc.line(setX(6), 60, setX(77), 60, "DF")
        doc.setFillColor(222, 215, 215);
        doc.rect(setX(6), 125, 98, 10, "F");
        doc.rect(setX(6), 145, 98, 10, "F");

        doc.setFontSize(14);
        doc.text(`${order.staff.deliveryService?.receiver?.lastName || 'Error: undefined'}`, setX(8),65);
        doc.text(`${order.staff.deliveryService?.receiver?.middleName || ''}`, setX(8),73);
        doc.text(`${order.staff.deliveryService?.receiver?.firstName || 'Error: undefined'}`, setX(50),65);
        doc.text(`${order.staff.deliveryService?.receiver?.cellNumber || 'Error: undefined'}`, setX(38),77);
        doc.rect(setX(81), 55, 30, 25, "D")
        doc.rect(setX(115), 55,30, 25, "D")
        doc.text(`Розмір:`, setX(83),60);
        doc.text(`Вага:`, setX(117),60);

        //Order table section
        doc.setFontSize(24);
        doc.text(`Замовлення`, setX(30),90);
        doc.setFontSize(12);
        doc.line(setX(6), 95, setX(104), 95, "DF")
        doc.text(`Рівень`, setX(9),106);
        doc.text(`I`, setX(15),122);
        doc.text(`II`, setX(14),132);
        doc.text(`III`, setX(13),142);
        doc.text(`IV`, setX(14),152);
        doc.line(setX(6), 95, setX(6), 155, "DF")
        doc.text(`Рос`, setX(29),102);
        doc.text(`Укр`, setX(42),102);
        doc.text(`Рос`, setX(55),102);
        doc.text(`Укр`, setX(68),102);
        doc.text(`Рос`, setX(81),102);
        doc.text(`Укр`, setX(94),102);

        doc.line(setX(26), 95, setX(26), 155, "DF")
        doc.line(setX(39), 95, setX(39), 155, "DF")
        doc.line(setX(52),95, setX(52), 155, "DF")
        doc.line(setX(65), 95, setX(65), 155, "DF")
        doc.line(setX(78), 95, setX(78), 155, "DF")
        doc.line(setX(91), 95, setX(91), 155, "DF")
        doc.line(setX(104), 95, setX(104), 155, "DF")

        let seriesRuStartX = 30;
        let seriesUaStartX = 43;
        let seriesShiftX = 0;
        for (let i = 1; i < order.lessons.series.season.length; i++) {
            doc.text(`${order.lessons.series.currentSeries.value}${order.lessons.series.season[i]}`,
                setX(seriesRuStartX + seriesShiftX),112);
            doc.text(`${order.lessons.series.currentSeries.value}${order.lessons.series.season[i]}`,
                setX(seriesUaStartX + seriesShiftX),112);

            seriesShiftX += 26;
        }

        let sortedOrder = Object.values(order?.lessons?.order)
            .filter(cell => cell.month <= 12)
            .sort((a, b) => {
                if (parseInt(a.cellId) > parseInt(b.cellId)) {
                    return 1;
                }
                if (parseInt(a.cellId) < parseInt(b.cellId)) {
                    return -1;
                }
                return 0;
            });

        let startX = 30;
        let startY = 122;
        let shiftX = 13;
        let shiftY = 10;
        let rowCellsCount = 6;

        sortedOrder.forEach((cell, i) => {
            let cellId = parseInt(cell.cellId);

            doc.text(cell.value, setX(startX) + (shiftX * (cellId - Math.floor(cellId / rowCellsCount) * rowCellsCount)), startY + (shiftY * Math.floor(cellId / rowCellsCount)));
        })

        doc.text(`${order?.lessons?.lessons?.monthKey[0] || ''}`, setX(34),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[0] || ''}`, setX(54),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[1] || ''}`, setX(74),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[1] || ''}`, setX(94),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[2] || ''}`, setX(114),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[2] || ''}`, setX(134),111);

        doc.line(setX(26), 105, setX(104), 105, "DF")
        doc.line(setX(6), 115, setX(104), 115, "DF")
        doc.line(setX(6), 125, setX(104), 125, "DF")
        doc.line(setX(6), 135, setX(104), 135, "DF")
        doc.line(setX(6), 145, setX(104), 145, "DF")
        doc.line(setX(6), 155, setX(104), 155, "DF")

        //Notes section
        doc.text("Нотатки: ", setX(118),92);
        doc.line(setX(108), 95, setX(144), 95, "DF")
        doc.setFontSize(10);
        doc.text(`${order.notes || ''}`, setX(108),100, { maxWidth: 40 });

        //Materials section
        doc.line(setX(6), 158, setX(146), 158, "DF")
        doc.text(`Назва`, setX(25),162);
        doc.text(`Назва`, setX(93),162);
        doc.text(`Рос`, setX(55),162);
        doc.text(`Рос`, setX(127),162);
        doc.text(`Укр`, setX(65),162);
        doc.text(`Укр`, setX(137),162);
        doc.line(setX(6), 164, setX(146), 164, "DF")
        doc.setFontSize(10);

        let X = 7;
        let Y = 164;
        let stepShift  = 4;
        let nextColumnShift  = 70;

        Object.values(order.materials).forEach((material, i) => {
            if(material.id) {
                if(i === 10) Y = 164;

                Y = Y + stepShift;

                if(i <= 9) {
                    doc.text(material.text.substring(0, 22), setX(X), Y);
                    doc.text(material.amount['ru']
                        ? material.amount['ru']
                        : ''
                        , setX(X+50), Y);
                    doc.text(material.amount['ua']
                        ? material.amount['ua']
                        : ''
                        , setX(X+60), Y);

                    if(i % 2) {
                    } else {
                        doc.setFillColor(222, 215, 215);
                        doc.rect(setX(X), Y+1, 67, 4, "F");
                    }

                } else {
                    doc.text(material.text.substring(0, 22), setX(X + nextColumnShift), Y);
                    doc.text(material.amount['ru']
                        ? material.amount['ru'] + "#"
                        : ''
                        , setX(X+nextColumnShift+50), Y);
                    doc.text(material.amount['ua']
                        ? material.amount['ua']
                        : ''
                        , setX(X+nextColumnShift+60), Y);
                    if(i % 2) {
                    } else {
                        doc.setFillColor(222, 215, 215);
                        doc.rect(setX(X+nextColumnShift-3), Y+1, 71, 4, "F");
                    }
                }

            }
        })

        doc.line(setX(6), 158, setX(6), 205, "DF")
        doc.line(setX(54), 158, setX(54), 205, "DF")
        doc.line(setX(64), 158, setX(64), 205, "DF")
        doc.line(setX(74), 158, setX(74), 205, "DF")
        doc.line(setX(125), 158, setX(125), 205, "DF")
        doc.line(setX(136), 158, setX(136), 205, "DF")
        doc.line(setX(146), 158, setX(146), 205, "DF")

        setOrder(doc.output('bloburi', {filename: `${order.staff.lastName}.pdf`}))
        setOpen(true);
    }


    useEffect(() => {
            // console.log('OrderList -> useEffect - 1');
        let mounted = true;

        fetchOrderList()
            .then(items => {
                if(mounted) {
                    setOrderList(items)
                    props.setOrderList({...items});
                }
            })

        return () => mounted = false;
    }, [])

    useEffect(() => {
        let mounted = true;

        setEnvironment(props.environment);

        return () => mounted = false;
    }, [props.environment])

    // console.log('render OrderList', props.environment.series.season[0]);

    return (
        <Segment>
            <Dimmer active={isSpinner}>
                <Loader inverted />
            </Dimmer>
        <div className={'new-order-container'} style={styles.newOrderContainer}>
            <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size='fullscreen'
            >
                <Modal.Actions>
                    <Button color='green' inverted onClick={() => setOpen(false)}>Закрити</Button>
                </Modal.Actions>
                <Modal.Content>
                    <iframe src={order} height={styles.temporaryPreviewPdf.height} width={styles.temporaryPreviewPdf.width}/>
                </Modal.Content>
            </Modal>
            <div className={'print-all-orders'} style={styles.printAllOrders}>
                <Button basic color='black' content='Друкувати всі замовлення' onClick={createOrdersPdf}/>
            </div>
            <div className="order-list-container" style={styles.newOrder}>
                <div className={'order-list'} style={styles.orderList}>
                    <Table celled structured striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center'>#</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Інструктор</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Телефон</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Доставка</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Видалити</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Виконано</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                Object.values(orderList)
                                    .filter(order => environment.series.season[0] === order.lessons.series.season[0] && environment.year === order.lessons.year)
                                    .sort((a, b) => {
                                        if (a.staff.deliveryService.name > b.staff.deliveryService.name) {
                                            return 1;
                                        }
                                        if (a.staff.deliveryService.name < b.staff.deliveryService.name) {
                                            return -1;
                                        }
                                        if (a.staff.deliveryService.name === b.staff.deliveryService.name && a.staff.lastName > b.staff.lastName) {
                                            return 1;
                                        }
                                        if (a.staff.deliveryService.name === b.staff.deliveryService.name && a.staff.lastName < b.staff.lastName) {
                                            return -1;
                                        }
                                        return 0;
                                    })
                                    .map((order, i) => {
                                        return (
                                            <Table.Row key={`order-row${i}`}>
                                                <Table.Cell textAlign='left' style={{paddingLeft: 20}} onClick={() => {
                                                    createOrderPdf(order.id, 0);
                                                }}>{i + 1}</Table.Cell>
                                                <Table.Cell textAlign='left' style={{paddingLeft: 20,
                                                    color: order.staff.deliveryService.name === "Нова Пошта"
                                                        ? "#DA2A2B"
                                                        : order.staff.deliveryService.name === "Укр Пошта"
                                                            ? "#FFC626"
                                                            : "black"}} onClick={() => {
                                                    createOrderPdf(order.id, 0);
                                                }}>{`${order.staff.lastName} ${order.staff.firstName}`}</Table.Cell>
                                                <Table.Cell textAlign='left' style={{paddingLeft: 20,
                                                    color: order.staff.deliveryService.name === "Нова Пошта"
                                                        ? "#DA2A2B"
                                                        : order.staff.deliveryService.name === "Укр Пошта"
                                                            ? "#FFC626"
                                                            : "black"}}
                                                    // onClick={() => createOrderPdf(order.id, 0)}
                                                >{`${order.staff.cellNumber}`}</Table.Cell>
                                                <Table.Cell textAlign='left' style={{
                                                    paddingLeft: 20,
                                                    color: order.staff.deliveryService.name === "Нова Пошта"
                                                        ? "#DA2A2B"
                                                        : order.staff.deliveryService.name === "Укр Пошта"
                                                            ? "#FFC626"
                                                            : "black"
                                                }}
                                                onClick={() => createOrderPdf(order.id, 0)}
                                                >{`${order.staff.deliveryService.name} -> ${order.staff.deliveryService.city}`}</Table.Cell>
                                                <Table.Cell textAlign='left' style={{paddingLeft: 20}}>
                                                    {
                                                        !order.doneTime
                                                            ? <Button
                                                                key={`remove-order-button_${order.id}`}
                                                                icon
                                                                fluid={false}
                                                                basic
                                                                onClick={async () => {
                                                                    try {
                                                                        window.scrollTo(0, 0);
                                                                        setIsSpinner(true);
                                                                        await removeOrder({action: "removeOrder", orderId: order.id});
                                                                    } finally {
                                                                        await fetchOrderList().then(items => setOrderList(items))
                                                                        setIsSpinner(false);
                                                                    }
                                                                }}
                                                            >
                                                                <Icon
                                                                    name='delete'
                                                                    color='red'>
                                                                </Icon>
                                                            </Button>
                                                            : ''
                                                    }
                                                </Table.Cell>
                                                <Table.Cell textAlign='left' style={{paddingLeft: 20}}>
                                                    {
                                                        !order.doneTime
                                                            ?   <Button
                                                                    key={`remove-order-button_${order.id}`}
                                                                    icon
                                                                    fluid={false}
                                                                    basic
                                                                    onClick={async () => {
                                                                        try {
                                                                            setIsSpinner(true);
                                                                            await doneOrder({action: "doneOrder", orderId: order.id});
                                                                        } finally {
                                                                            await fetchOrderList().then(items => setOrderList(items)).finally(() => {
                                                                                setIsSpinner(false);
                                                                            })
                                                                        }
                                                                    }}
                                                                >
                                                                        <Icon
                                                                            name='check'
                                                                            color='green'>
                                                                        </Icon>
                                                                    </Button>
                                                                : new Date(order.doneTime).toDateString()
                                                        }

                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                })
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
        </Segment>
    );
}

export default OrderList;
