import React, {useState} from 'react';
import { Dropdown } from 'semantic-ui-react';

function SearchSeries(props) {
    const [placeholder, setPlaceholder] = useState(`Серія ${props.environment.series.currentSeries.value} (${props.environment.series.season[0]} ${props.environment.year})`);
    if(!props.orderList) return (<div className={'search-staff'} style={{marginLeft: 30}}>...Loading</div>)
    const orderedSeries = [...new Set(Object.values(props.orderList).map(order => {
        let series;

        if(order.lessons.hasOwnProperty("series")) {
            series = [
                order.lessons.series,
                order.lessons.year,
            ]
        } else {
            series = [
                props.environment.series,
                props.environment.year
            ]
        }

        return  series;
    }).map(JSON.stringify))].map(JSON.parse);

    let seriesSearchList = orderedSeries.map(series => {
        // console.log(typeof series[0], series[0]);
        if(!series[0] && !series[1]) return;
        // console.log('series', series);
        return {
            key: `${series[0].currentSeries.key}_${series[0].season[0]}_${series[1]}`,
            id: series,
            text: `Серія ${series[0].currentSeries.value} (${series[0].season[0]} ${series[1]})`,
            onClick: (e, d) => {
                setPlaceholder(d.text);

                let nextEnvironment = {...props.environment};
                nextEnvironment.series = d.id[0];
                nextEnvironment.year = d.id[1];

                props.setEnvironment(nextEnvironment);
            }
        };
    });

    return (
        <div className={'search-staff'} style={{marginLeft: 30}}>
            <Dropdown
                // placeholder={placeholder}
                placeholder={placeholder}
                // fluid
                search
                selection
                options={seriesSearchList}
            />
        </div>
    );
}

export default SearchSeries;
