import React, {useEffect, useState} from 'react';
import { Table, Input, Loader } from 'semantic-ui-react';
import {fetchEnvironment} from "../request/fetchEnvironment";
import {fetchOrderList} from "../request/fetchOrderList";


function LessonsCard(props) { //todo: update lessons form with completed order
    const styles = {
        lessonsCard: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flexWrap: "wrap",
            // backgroundColor: 'yellow',
            width: '100%',
            marginTop: 30
        },
        lessonsSeriesContainer: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: "wrap",
            // backgroundColor: 'yellow',
            width: '100%',
            marginTop: 30
        }
    };
    const getSeason = d => Math.floor((d / 12 * 4)) % 4;
    const getSeries = (currentYear, baseYear) => Math.floor((currentYear - baseYear)) % 3;
    const currentDate = new Date();
    const [lessons, setLessons] = useState({...props.environment.current});
    const orderLesson = (item) => {
        try {
            let metadata = item.tabIndex.split('_'); // [ ua 1 C 4 0]
            let lang = metadata[0];
            let level = metadata[1];
            let series = metadata[2];
            let month = metadata[3];
            let cellId = metadata[4];
            let isAutocomplete = lessons.series.season.filter((season, i) => season === month ? i === 1 : false).length > 0;
            let nextLessonOrder = {...lessons.order};
            nextLessonOrder = Object.assign(nextLessonOrder, {[item.tabIndex]: {cellId, lang, level, series: lessons.series.currentSeries.value, month, value: item.value}})

            let nextMonthSeries = `${lang}_${level}_${series}_${parseInt(month) + 1}_${(parseInt(cellId) + 2).toString()}`;
            let lastMonthSeries = `${lang}_${level}_${series}_${parseInt(month) + 2}_${(parseInt(cellId) + 4).toString()}`;

            if(isAutocomplete) nextLessonOrder = Object.assign(nextLessonOrder, {[nextMonthSeries]: {cellId: (parseInt(cellId) + 2).toString(), lang, level, series: lessons.series.currentSeries.value, month: (parseInt(month) + 1).toString(), value: item.value}});
            if(isAutocomplete) nextLessonOrder = Object.assign(nextLessonOrder, {[lastMonthSeries]: {cellId: (parseInt(cellId) + 4).toString(), lang, level, series: lessons.series.currentSeries.value, month: (parseInt(month) + 2).toString(), value: item.value}});

            let nextLessons = {...lessons, order: nextLessonOrder};

            // console.log(nextLessonOrder);
            // console.log(JSON.stringify(nextLessonOrder, ' ', 4));


            props.setOrder({...props.order, lessons: {...nextLessons}});

            setLessons(nextLessons);
        } catch (e) {
            console.log('e', e);
        }
    };
    const getOrderValue = (id) => props.order.lessons.order && props.order.lessons.order[id] ? props.order.lessons.order[id].value : '';
    /*
    const setSelectedSeries = async (selectedSeries) => {
        // console.log('selectedSeries', selectedSeries);
        // console.log(props.environment, props.setEnvironment);
        props.environment.series = selectedSeries[0];
        props.environment.year = selectedSeries[1];
        lessons.series = selectedSeries[0];
        lessons.year = selectedSeries[1];
        // console.log('lessons', lessons);
        props.setEnvironment({...props.environment});
        setLessons(lessons);

        // console.log(props);

        let nextOrder = {...props.order};
        // nextOrder.staff = {...selectedStaff[0]};
        // nextOrder.selectedCellNumberId = selectedCellNumberId;
        // nextOrder.selectedServiceId = deliveryService.id;

        Object.values(props.orderList).forEach(item => {
            if(
                item.staff.id === props.order.staff.id
                &&
                item.lessons.series.currentSeries.key === props.environment.series.currentSeries.key
                &&
                item.lessons.series.season[0] === props.environment.series.season[0]
            ) {
                nextOrder = {
                    ...nextOrder,
                    id: item.id,
                    lessons: {...item.lessons},
                    materials: {...item.materials},
                    notes: item.notes,
                    doneTime: item.doneTime,
                    photoOrdered: {...item?.photoOrdered},
                }
            }
        })

        props.setOrder(nextOrder);
    };
    */
    /*
    useEffect(() => {
        // console.log('useEffect-1', props.order.lessons);
        let mounted = true;
        fetchEnvironment()
            .then(items => {
                // console.log(items);
                if(mounted) {
                    let year = items.baseYear;
                    let series = {
                            season: items.season[getSeason(currentDate.getMonth()) + 1],
                            currentSeries: items.series[getSeries(currentDate.getUTCFullYear(), year)]
                        };
                    // console.log(props.order);
                    let lessons = {
                        year,
                        series,
                        level: items.level,
                        lang: items.languages,
                        order: {},
                    }

                    // console.log(JSON.stringify(lessons, " ", 4));

                    setLessons(lessons);
                    props.setOrder({...props.order, lessons});
                }
            })
        return () => mounted = false;
    }, [])
    */

    /*
    useEffect(() => {
        console.log('useEffect-2', props.order.lessons);
        // console.log('useEffect', lessons.order);

        lessons.order = {...props.order.lessons.order};
        setLessons(lessons);

    }, [props.order && props.order.lessons && props.order.lessons.order && Object.keys(props.order.lessons.order).length > 0])
    // }, [lessons && lessons.order && Object.keys(lessons.order).length === 0])
    */
    // console.log('lessons', lessons);

    if(!lessons.series) return <Loader active inline />;
    let cellId = 0;

    // console.log(JSON.stringify(lessons.order, " ", 4));

    // console.log('render, props', props.order, Object.values(props.order.staff).length);

    return (
        <div className={'lessons-card'} style={styles.lessonsCard}>
            <div className={'lessons-series-container'} style={styles.lessonsSeriesContainer}>
                <h4>{`Замовлення на ${lessons.year} (${lessons.series.season[0]}).  Серія  ${lessons.series.currentSeries.value}.`}</h4>
                {/*
                    Object.values(props.order.staff).length > 1
                    ? <SearchSeries
                            orderList={props.orderList}
                            setSelectedSeries={setSelectedSeries}
                        />
                    : <div/>
                */}

            </div>
            <Table celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' rowSpan='2'>Рівень</Table.HeaderCell>
                            {
                                Object.values(lessons.series.season).map((monthIndex, i) => {
                                    if(i > 0) {
                                        return lessons.lang.map(lang => {
                                            return (<Table.HeaderCell key={`HeaderCell_${monthIndex}_${Object.values(lang)}`} textAlign='center'>{Object.values(lang)}</Table.HeaderCell>)
                                        })
                                    }
                                })
                            }
                    </Table.Row>
                    <Table.Row>
                        {
                            Object.values(lessons.series.season).map((monthIndex, i) => {
                                if(i > 0) {
                                    return lessons.lang.map(lang => {
                                        return (<Table.HeaderCell key={`series_${lessons.series.currentSeries.value}_${monthIndex}_${Object.values(lang)}`} textAlign='center'>{`${lessons.series.currentSeries.value}${monthIndex}`}</Table.HeaderCell>)
                                    })
                                }
                            })
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        Object.values(lessons.level).map(level => {
                            let cells = Object.values(lessons.series.season).map((monthIndex, i) => {
                                if(i > 0) {
                                    return lessons.lang.map(lang => {
                                        let id = `${Object.keys(lang)}_${level}_${lessons.series.currentSeries.key}_${monthIndex}_${cellId++}`;
                                        // console.log(id);
                                        return (<Table.Cell key={id} textAlign='center'>
                                            <Input value={ getOrderValue(id)} tabIndex={id} fluid size={'mini'} onChange={(e, d) => orderLesson(d)} />
                                        </Table.Cell>)
                                    })
                                } else {
                                    return (<Table.Cell key={level} textAlign='center'>{level}</Table.Cell>)
                                }
                            })
                            return <Table.Row key={`row_${level}`}>{cells}</Table.Row>
                        })
                    }
                </Table.Body>
            </Table>
        </div>
    );
}

export default LessonsCard;
