export async function removeStaff(staff) {
    try {
        // console.log('removeStaff', staff);
        return await fetch('https://8orow52vf2.execute-api.us-east-1.amazonaws.com/order', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': '*'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(staff) // body data type must match "Content-Type" header
        })
        .then(data => {
            console.log('response', data);
            data.json()
        })
    } catch (e) {
        return true;
    }
}
