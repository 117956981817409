import { v4 as uuidv4 } from 'uuid';

export default class Staff {
  constructor(
      firstName = '' ,
      lastName = '',
      middleName = '',
      cellNumbers = '',
      deliveryServices = '',
) {
    this.id = this.generateStaffId();
    this.createdAt = Date.now();

    this.firstName = firstName;
    this.lastName = lastName;
    this.middleName = middleName;
    this.cellNumbers = cellNumbers;
    this.deliveryServices = deliveryServices;
  }

  generateStaffId = () => {
    return uuidv4();
  };
}

export const fromCreateStaffEvent = addEvent => {
  try {
    if (!addEvent.firstName) {
      throw new ReferenceError('first name is required!');
    }
    if (!addEvent.firstName) {
      throw new ReferenceError('last name is required!');
    }
    if (!addEvent.cellNumbers) {
      throw new ReferenceError('cellphone number is required!');
    }

    let NewStaff = new Staff(
      addEvent.firstName,
      addEvent.lastName,
      addEvent.middleName,
      addEvent.cellNumbers,
      addEvent.deliveryServices,
    );

    return NewStaff;

  } catch (e) {
    return e.name + ': ' + e.message;
  }
};
