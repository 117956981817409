import React from 'react';
import { Dropdown } from 'semantic-ui-react';

function SearchStaff(props) {
    if(!props.staffSearchList) return (<div>...Loading</div>)

    let staffSearchList = props.staffSearchList.map(staff => {
        // console.log({staff});
        staff = {...staff, onClick: (e, d) => props.setSelectedStaffId(d)};

        return staff;
    });

    return (
        <div className={'search-staff'}>
            <Dropdown
                placeholder='Введіть пошуковий текст'
                // fluid
                search
                selection
                options={staffSearchList}
                // onSearchChange={(e, d) => {
                //     // selectedStaff={props.selectedStaff} setSelectedStaff={props.setSelectedStaff}
                //     console.log(d.searchQuery);
                // }}
            />
        </div>
    );
}

export default SearchStaff;
