import React, { useEffect, useState } from 'react';
import { fetchPendingOrders } from '../request/fetchPendingOrders';
import Card from '../components/Card';
import { Checkbox } from 'semantic-ui-react';

function PendingOrders(props) {
    const [list, setList] = useState([]);
    const [isDisplayAll, setIsDisplayAll] = useState(false);
    const updatePendingOrdersList = async () => {
        await fetchPendingOrders()
            .then(items => {
                // console.log(items);
                setList(items)
            });
    };

    useEffect(() => {
        let mounted = true;
        fetchPendingOrders()
            .then(items => {
                // console.log(JSON.stringify(items, ' ', 4));
                if(mounted) {
                    setList(items)
                }
            })
        return () => mounted = false;
    }, [])

    const styles = {
        pendingOrdersList: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: "wrap"
        },
        cardContainer: {
            margin: 5
        },
        showAll: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    };

    return (
        <div className="PendingOrders">
            <div className={'show-all'} style={styles.showAll}>
                <Checkbox label='дивитись всі' onChange={(e, d) => setIsDisplayAll(d.checked)}/>
            </div>
            <div className={"pending-orders-list"} style={styles.pendingOrdersList}>
                {Object.values(list).map(item => {
                    if(!isDisplayAll && item.isOrdered) return;
                    return <div key={item.id} className={'card-container'} style={styles.cardContainer}>
                        <Card
                            setPhoto={props.setPhoto}
                            setActiveIndex={props.setActiveIndex}
                            item={item}
                            updatePendingOrdersList={updatePendingOrdersList}
                        />
                    </div>
                })}
            </div>
        </div>
    );
}

export default PendingOrders;
