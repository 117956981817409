import React, {useState} from 'react';
import { Card as CardSemantic, Icon, Image, Button } from 'semantic-ui-react';
import {removeFile} from '../request/removeFile';

const Card = (props) => {
    const [isRemoveFile, setIsRemoveFile] = useState(false);
    let creator = props.item.creator ? `${props.item.creator.last_name} ${props.item.creator.first_name}`: "unknown"
    let pendingDate = new Date(parseInt(props.item.pendingDate) * 1000);
    pendingDate = `${pendingDate.getDate()}/${pendingDate.getMonth()+1}/${pendingDate.getFullYear()} ${pendingDate.getHours()}:${pendingDate.getMinutes()}`

    return (
        <CardSemantic>
            <div
                style={{width: '100%', height: '1005'}}
                onClick={() => {
                    props.setActiveIndex(1);
                    props.setPhoto(props.item);
                }}
            >
                <Image src={props.item.photoUrl} centered style={{height: 150}} />
            </div>
            <CardSemantic.Content onClick={() => {
                props.setActiveIndex(1);
                props.setPhoto(props.item);
            }}>
                <CardSemantic.Meta>
                    <span className='date'>{pendingDate}</span>
                </CardSemantic.Meta>
                <CardSemantic.Description>{creator}</CardSemantic.Description>
            </CardSemantic.Content>
            <CardSemantic.Content extra textAlign={'right'}>
                <Button
                    icon
                    fluid={false}
                    basic
                    onClick={async () => {
                        try {
                            await removeFile({action: "removeFile", removedFileId: props.item.id});
                        } finally {
                            props.updatePendingOrdersList();
                        }
                    }}
                >
                    <Icon
                        name='delete'
                        color='red'>
                    </Icon>
                </Button>
            </CardSemantic.Content>
        </CardSemantic>
)}

export default Card
