import React, {useEffect, useState} from 'react';
import {Input, Checkbox, Form, Select, Button, Icon, Dimmer, Loader, Segment} from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import SearchStaff from "./SearchStaff";
import {fetchOrderList} from "../request/fetchOrderList";
import {removeOrder} from "../request/removeOrder";

const styles = {
    staffCard: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // backgroundColor: 'yellow',
        width: '100%',
    },
    searchStaff: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // backgroundColor: 'yellow',
        width: '100%',
    },
    staffContainer: {
     display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // backgroundColor: 'red',
        width: '100%',
        marginTop: 20
    },
    staffPersonalData: {
     display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // backgroundColor: 'red',
        width: '100%',
        // marginTop: 20
    },
    staffDelivery: {
     display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // backgroundColor: 'blue',
        width: '100%',
        marginTop: 20
    },
    deliveryServiceTitle: {
     display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        // backgroundColor: 'blue',
        width: '100%',
        paddingLeft: 20,
    },
    cellNumber: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // backgroundColor: 'blue',
        width: '100%',
        paddingLeft: 20,
    },
    admin: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        // backgroundColor: 'blue',
        width: '100%',
        paddingLeft: 20,
    },
    cellNumberTitle: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // backgroundColor: 'blue',
        width: '70%',
        paddingLeft: 20,
    },
    cellNumberAddButton: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        // backgroundColor: 'red',
        width: '30%',
        height: '100%',
        paddingLeft: 20,
    }
};

function StaffCard(props) {
    // console.log('props.selectedStaff', props.selectedStaff);
    const [staff, setStaff] = useState({...props.selectedStaff});
    const [selectedServiceId, setSelectedServiceId] = useState(props.selectedStaff?.selectedServiceId || '');
    const [deliveryServices, setDeliveryServices] = useState([ //todo: fetch deliveryServices from server
        { key: 'novaposhta', text: 'Нова Пошта', value: '5f4b9b09-7b22-4918-95ea-a903b431c4a2'},
        { key: 'ukrposhta', text: 'Укр Пошта', value: '2d66f6ff-5906-440c-9f56-a9417037e62e'},
        { key: 'selfpickup', text: 'Самовивіз', value: 'a9417037-5906-4f5c-9406-2d66f6ffe62e'},
    ]);
    const [isAddCellNumber, setIsAddCellNumber] = useState(false);
    const [cellNumbers, setCellNumbers] = useState(Object.values(props.selectedStaff.cellNumbers).map(cellNumber => {
        return {
            id: cellNumber.id,
            key: cellNumber.number,
            text: cellNumber.number,
            value: cellNumber.number,
            default: cellNumber.isDefault,
            onClick: (e, d) => handleCellNumber(d),
        };
    }));
    const [newCellNumber, setNewCellNumber] = useState({});
    const [selectedCellNumberId, setSelectedCellNumberId] = useState(props.selectedStaff?.selectedCellNumberId || '');
    const [isSpinner, setIsSpinner] = useState(false);
    const handleCellNumber = (d) => setSelectedCellNumberId(d.id);
    const setSelectedStaffId = async (d) => {
        // console.log(d, props.staffList);
        if(!d.id) return;
        // console.log(d, props.staffList);

        let selectedStaff = Object.values(props.staffList).filter(staff => staff.id === d.id);

        delete selectedStaff[0].deliveryServices.undefined;

        if(selectedStaff.length === 0) throw new ReferenceError('staff not found')
        if(selectedStaff.length > 1) throw new ReferenceError(`duplicated staff ${selectedStaff.length}`)
        if(selectedStaff.length === 1) {
            props.setSelectedStaff(selectedStaff[0])

            setStaff({...selectedStaff[0]});

            let deliveryService = Object.values(selectedStaff[0].deliveryServices).filter(currentDeliveryService => {
                return currentDeliveryService.isDefaultService
            })[0];

            // console.log({deliveryService});

            setSelectedServiceId(deliveryService.id);

            let selectedCellNumberId = Object.values(selectedStaff[0].cellNumbers).filter(cellNumber => cellNumber.isDefault)[0].id;
            setSelectedCellNumberId(selectedCellNumberId);

            setCellNumbers(Object.values(selectedStaff[0].cellNumbers).map(cellNumber => {
                return {
                    id: cellNumber.id,
                    key: cellNumber.number,
                    text: cellNumber.number,
                    value: cellNumber.number,
                    default: cellNumber.isDefault,
                    onClick: (e, d) => handleCellNumber(d),
                };
            }));

            // console.log('let nextOrder = {...props.order};', props.order);
            // console.log('let nextOrder = {...props};', props.environment.series.currentSeries.key, props.environment.series.season[0]);
            let nextOrder = {...props.order};
            nextOrder.staff = {...selectedStaff[0]};
            nextOrder.selectedCellNumberId = selectedCellNumberId;
            nextOrder.selectedServiceId = deliveryService.id;

            await fetchOrderList()
                .then(items => {
                    props.setOrderList({...items});
                    Object.values(items).forEach(item => {
                        // console.log(item.lessons.series.currentSeries.key === props.environment.series.currentSeries.key);
                        // console.log(item.lessons.series.season[0] === props.environment.series.season[0]);

                        if(
                            item.staff.id === d.id
                            &&
                            item.lessons.series.currentSeries.key === props.environment.series.currentSeries.key
                            &&
                            item.lessons.series.season[0] === props.environment.series.season[0]
                        ) {
                            // console.log('ordered', item);
                            nextOrder = {
                                ...nextOrder,
                                id: item.id,
                                lessons: {...item.lessons},
                                materials: {...item.materials},
                                notes: item.notes,
                                doneTime: item.doneTime,
                                photoOrdered: {...item?.photoOrdered},
                            }
                        }
                    })
                }).finally(() => {
                    // console.log('nextOrder', nextOrder);
                    props.setOrder(nextOrder);
                })


        }
    };
    const setOrder = (nextStaff) => {
        let nextOrder = {...props.order};
        nextOrder.staff = {...nextStaff};
        nextOrder.selectedCellNumberId = selectedCellNumberId;
        nextOrder.selectedServiceId = selectedServiceId;

        props.setSelectedStaff(nextStaff);

        props.setOrder(nextOrder);

        setStaff(nextStaff);
    };

    useEffect(() => {
        // console.log('useEffect 1');
        if(Object.values(props.staffList).length > 0) {
            // console.log('useEffect(() => {');
            // console.log(props?.photo, props.staffList);
        }
    }, [Object.values(props.staffList).length > 0])

    useEffect(() => {
        // console.log('useEffect 2', props);
            setSelectedStaffId({id: props?.photo?.staffId});
        // console.log('useEffect props.selectedStaff');
        if(props.selectedStaff.firstName.length === 0) {
            // console.log('update selectedStaff', props.selectedStaff);

            setStaff({...props.selectedStaff});

        }

    }, [props.selectedStaff.firstName.length === 0]);

    // console.log({staff});
    // console.log({selectedServiceId});
    // console.log(staff.deliveryServices);

    // console.log('RENDER', props.environment);

    return (
        <div className={'staff-card'} style={styles.staffCard}>
            <Dimmer active={isSpinner}>
                <Loader inverted />
            </Dimmer>
            <div className={'search-staff'} style={styles.searchStaff}>
                <h4>Інструктор</h4>
                <SearchStaff
                    staffSearchList={props.staffSearchList}
                    setSelectedStaffId={setSelectedStaffId}
                />
            </div>
            <div className={'staff-container'} style={styles.staffContainer}>
                <div className={'staff-personal-data'} style={styles.staffPersonalData}>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field
                                id='lastName'
                                control={Input}
                                label='Призвіще'
                                placeholder='Призвіще'
                                value={staff.lastName}
                                error={props.formErrors?.isLastName?.text?.length > 0 ? {content: props.formErrors?.isLastName?.text, pointing: 'above'}: null}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff[d.id] = d.value;

                                    if(props.formErrors?.isLastName?.text?.length >= 0) {
                                        let nextFormError = {...props.formErrors};
                                        if(d.value.length === 0) {
                                            nextFormError.isLastName.text = nextFormError.isLastName.deFaultText;
                                        } else {
                                            nextFormError.isLastName.text = '';
                                        }
                                        props.setFormErrors(nextFormError);
                                    }

                                    setOrder(nextStaff);
                                }}

                            />
                            <Form.Field
                                id='firstName'
                                control={Input}
                                label="Ім'я"
                                placeholder="Ім'я"
                                value={staff.firstName}
                                error={props.formErrors?.isFirstName?.text?.length > 0 ? {content: props.formErrors?.isFirstName?.text, pointing: 'above'}: null}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff[d.id] = d.value;

                                    if(props.formErrors?.isFirstName?.text?.length >= 0) {
                                        let nextFormError = {...props.formErrors};
                                        if (d.value.length === 0) {
                                            nextFormError.isFirstName.text = nextFormError.isFirstName.deFaultText;
                                        } else {
                                            nextFormError.isFirstName.text = '';
                                        }
                                        props.setFormErrors(nextFormError);
                                    }

                                    setOrder(nextStaff);
                                }}
                            />
                            <Form.Field
                                id='middleName'
                                control={Input}
                                label='По батькові'
                                placeholder='По батькові'
                                value={staff.middleName}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff[d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                            {
                                staff.firstName.length > 0
                                    ? <Form.Field
                                        id='removeStaff'
                                        style={{color: 'red', marginTop: 26}}
                                        control={Button}
                                        placeholder='Видалити запис'
                                        onClick={async () => {
                                            try {
                                                window.scrollTo(0, 0);
                                                setIsSpinner(true);
                                                await props.deleteStaff(staff.id);
                                                setIsSpinner(false);
                                                } catch (e) {console.log(e)}
                                        }}
                                    >Видалити запис</Form.Field>
                                    : ''
                            }

                        </Form.Group>
                        <Form.Group widths='equal'>
                            <div className={'cell-number-title'} style={styles.cellNumber}>
                                <div className={'cell-number-title'} style={styles.cellNumberTitle}>
                                    <div style={{marginBottom: 10, fontWeight: 'bold'}}>Телефон:</div>
                                    <div>{Object.keys(staff.cellNumbers).length > 0 ? staff.cellNumbers[selectedCellNumberId].number : ''}</div>
                                </div>
                                <div style={styles.cellNumberAddButton}>
                                    {
                                        isAddCellNumber
                                            ? <Button icon fluid={false}>
                                                <Icon
                                                    name='check'
                                                    color='green'
                                                    onClick={() => {
                                                        let nextStaff = {...staff};
                                                        let  nextCellNumbers = {...nextStaff.cellNumbers};
                                                        nextCellNumbers = {...nextCellNumbers, [newCellNumber.id]: {...newCellNumber}}
                                                        nextStaff.cellNumbers = {...nextCellNumbers}
                                                        setStaff({...nextStaff});
                                                        setSelectedCellNumberId(newCellNumber.id)
                                                        setCellNumbers(Object.values(nextStaff.cellNumbers).map(cellNumber => {
                                                            return {
                                                                id: cellNumber.id,
                                                                key: cellNumber.number,
                                                                text: cellNumber.number,
                                                                value: cellNumber.number,
                                                                default: cellNumber.isDefault,
                                                                onClick: (e, d) => handleCellNumber(d),
                                                            };
                                                        }));
                                                        setIsAddCellNumber(false);

                                                        setOrder(nextStaff);
                                                    }}
                                                />
                                            </Button>
                                            : ''
                                    }
                                </div>
                            </div>

                            <Form.Field
                                id='number'
                                control={Select}
                                options={cellNumbers}
                                label={{ children: 'Оберіть телефон', htmlFor: 'form-select-control-delivery-service' }}
                                placeholder='Оберіть телефон'
                                error={props.formErrors?.isCellNumber?.text?.length > 0 ? {content: props.formErrors?.isCellNumber?.text, pointing: 'above'}: null}
                                search
                                searchInput={{ id: 'form-select-control-delivery-service' }}
                                onSearchChange={(e, d) => {
                                    if(d.options.filter(option => option.value.includes(d.searchQuery)).length < 1) {
                                        setIsAddCellNumber(true);
                                        setNewCellNumber({
                                            id: uuidv4(),
                                            number: d.searchQuery,
                                            isDefault: false
                                        })
                                    } else {
                                        setNewCellNumber({});
                                        setIsAddCellNumber(false);
                                    }

                                    if(props.formErrors?.isCellNumber?.text?.length >= 0) {
                                        let nextFormError = {...props.formErrors};
                                        if (d.searchQuery.length === 0) {
                                            nextFormError.isCellNumber.text = nextFormError.isCellNumber.deFaultText;
                                        } else {
                                            nextFormError.isCellNumber.text = '';
                                        }
                                        props.setFormErrors(nextFormError);
                                    }

                                }}
                            />
                        </Form.Group>
                        <Form.Field
                            id='isDefault'
                            control={Checkbox}
                            checked={Object.keys(staff.cellNumbers).length > 0 ? staff.cellNumbers[selectedCellNumberId].isDefault : false}
                            error={staff.cellNumbers[selectedCellNumberId]?.isDefault ? null : Object.keys(staff.cellNumbers).length === 0 ? null : props.formErrors?.isDefaultCellNumber?.text.length > 0 ? {content: props.formErrors?.isDefaultCellNumber?.text, pointing: 'left'} : null}
                            // error={{content: "error", pointing: 'left'}}
                            label='за замовченням'
                            onChange={(e, d) => {
                                let nextStaff = {...staff};

                                Object.values(nextStaff.cellNumbers).reduce((prev, item) => {
                                    item.isDefault = false;
                                    return {...prev, [item.id]: {...item}};
                                }, {});

                                if(props.formErrors?.isDefaultCellNumber?.text?.length >= 0) {
                                    let nextFormError = {...props.formErrors};
                                    if (d.checked) {
                                        nextFormError.isDefaultCellNumber.text = '';
                                    } else {
                                        nextFormError.isDefaultCellNumber.text = nextFormError.isDefaultCellNumber.deFaultText;
                                    }
                                    props.setFormErrors(nextFormError);
                                }

                                nextStaff.cellNumbers[selectedCellNumberId].isDefault = d.checked;

                                setOrder(nextStaff);
                            }}
                        />
                        <Form.Group>
                            <div style={styles.admin}>
                                <Form.Field
                                    id='email'
                                    control={Input}
                                    label='e-mail'
                                    placeholder='електрона пошта'
                                    value={staff.email}
                                    onChange={(e, d) => {
                                        let nextStaff = {...staff};
                                        nextStaff[d.id] = d.value;

                                        setOrder(nextStaff);
                                    }}
                                />
                                <Form.Field
                                    id='isAdmin'
                                    style={{paddingLeft: 20}}
                                    control={Checkbox}
                                    checked={staff.isAdmin}
                                    label='адмін запис'
                                    onChange={(e, d) => {
                                        let nextStaff = {...staff};

                                        nextStaff.isAdmin = d.checked;

                                        setOrder(nextStaff);
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </div>
                <div className={'staff-delivery'} style={styles.staffDelivery}>
                    <Form>
                        <Form.Group widths='equal'>
                            <div className={'delivery-service-title'} style={styles.deliveryServiceTitle}>
                                <div style={{marginBottom: 10, fontWeight: 'bold'}}>Служба доставки:</div>
                                <div>{Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId].name : ''}</div>
                            </div>
                            <Form.Field
                                id='name'
                                control={Select}
                                options={deliveryServices}
                                label={{ children: 'Оберіть службу доставки', htmlFor: 'form-select-control-delivery-service' }}
                                placeholder='Оберіть службу доставки'
                                error={props.formErrors?.isDeliveryService?.text?.length > 0 ? {content: props.formErrors?.isDeliveryService?.text, pointing: 'above'}: null}
                                search
                                searchInput={{ id: 'form-select-control-delivery-service' }}
                                onChange={(e, d) => {
                                    if(props.formErrors?.isDeliveryService?.text?.length >= 0) {
                                        let nextFormError = {...props.formErrors};
                                        if (d.value.length > 0) {
                                            nextFormError.isDeliveryService.text = '';
                                        } else {
                                            nextFormError.isDeliveryService.text = nextFormError.isDeliveryService.deFaultText;
                                        }
                                        props.setFormErrors(nextFormError);
                                    }
                                    if(!staff.deliveryServices.hasOwnProperty(d.value)) {
                                        let nextStaff = {...staff};
                                        nextStaff.deliveryServices[d.value] = {
                                                id: d.value,
                                                name: d.options.filter(option => option.value === d.value)[0].text,
                                                city: '',
                                                region: '',
                                                department: '',
                                                isDefaultService: false,
                                                receiver: {
                                                    lastName: nextStaff.lastName,
                                                    firstName: nextStaff.firstName,
                                                    middleName: nextStaff.middleName,
                                                    cellNumber: nextStaff.cellNumbers[selectedCellNumberId]?.number || '',
                                                }
                                            }

                                        setOrder(nextStaff);
                                    }
                                    setSelectedServiceId(d.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field
                                id='city'
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                control={Input}
                                label='Місто'
                                placeholder='Місто'
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId].city : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId][d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                            <Form.Field
                                id='region'
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                control={Input}
                                label="Область"
                                placeholder="Область"
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId].region : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId][d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                            <Form.Field
                                id='department'
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                control={Input}
                                label='відділеня №'
                                placeholder='відділеня №'
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId].department : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId][d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                        </Form.Group>
                        <h5>Отримувач</h5>
                        <Form.Group>
                            <Form.Field
                                id='lastName'
                                control={Input}
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                label='Призвіще'
                                placeholder='Призвіще отримувача'
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId]?.receiver?.lastName || staff.lastName : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId].receiver[d.id] = d.value;

                                    setOrder(nextStaff);
                                }}

                            />
                            <Form.Field
                                id='firstName'
                                control={Input}
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                label="Ім'я"
                                placeholder="Ім'я отримувача"
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId]?.receiver?.firstName || staff.firstName : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId].receiver[d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                            <Form.Field
                                id='middleName'
                                control={Input}
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                label='По батькові'
                                placeholder='По батькові отримувача'
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId]?.receiver?.middleName || staff.middleName : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId].receiver[d.id] = d.value;

                                    setOrder(nextStaff);
                                }}
                            />
                            <Form.Field
                                id='cellNumber'
                                control={Input}
                                disabled={Object.keys(staff.deliveryServices).length === 0}
                                label={{ children: 'телефон', htmlFor: 'form-select-control-delivery-service' }}
                                placeholder='Вкажіть телефон отримувача'
                                value={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId]?.receiver?.cellNumber || staff.cellNumbers[selectedCellNumberId]?.number || '' : ''}
                                onChange={(e, d) => {
                                    let nextStaff = {...staff};
                                    nextStaff.deliveryServices[selectedServiceId].receiver[d.id] = d.value;

                                    setOrder(nextStaff);

                                }}
                            />
                        </Form.Group>
                        <Form.Field
                            id='isDefaultService'
                            control={Checkbox}
                            disabled={Object.keys(staff.deliveryServices).length === 0}
                            label='за замовченням'
                            checked={Object.keys(staff.deliveryServices).length > 0 ? staff.deliveryServices[selectedServiceId].isDefaultService : false}
                            error={staff.deliveryServices[selectedServiceId]?.isDefaultService ? null : Object.keys(staff.deliveryServices).length === 0 ? null : props.formErrors?.isDefaultService?.text.length > 0 ? {content: props.formErrors?.isDefaultService?.text, pointing: 'left'} : null}
                            onChange={(e, d) => {
                                let nextStaff = {...staff};

                                Object.values(nextStaff.deliveryServices).reduce((prev, item) => {
                                    item.isDefaultService = false;
                                    return {...prev, [item.id]: {...item}};
                                }, {});

                                if(props.formErrors?.isDefaultService?.text?.length >= 0) {
                                    let nextFormError = {...props.formErrors};
                                    if (d.checked) {
                                        nextFormError.isDefaultService.text = '';
                                    } else {
                                        nextFormError.isDefaultService.text = nextFormError.isDefaultService.deFaultText;
                                    }
                                    props.setFormErrors(nextFormError);
                                }

                                nextStaff.deliveryServices[selectedServiceId].isDefaultService = d.checked;

                                setOrder(nextStaff);
                            }}
                        />
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default StaffCard;
