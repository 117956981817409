import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { fetchStaffList } from '../request/fetchStaffList';

function SearchMaterials(props) {
    const [searchList, setSearchList] = useState([]);
    const transliterate = (text, engToRus) => {
            var
                rus = "і ї є щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(/ +/g),
                eng = "i yi e shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(/ +/g)
            ;
            // return function(text, engToRus) {
                var x;
                for(x = 0; x < rus.length; x++) {
                    text = text.split(engToRus ? eng[x] : rus[x]).join(engToRus ? rus[x] : eng[x]);
                    text = text.split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase()).join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
                }
                return text;
            // }
        };

    return (
        <div className={'search-staff'}>
            <Dropdown
                placeholder='Введіть пошуковий текст'
                fluid
                search
                selection
                options={searchList.concat(props.materials.map(material => {
                    material.onClick = (e, d) => props.setSelected(d)
                    return material;
                }))}
                onSearchChange={(e, d) => {
                    try {
                        let transliterated = [...transliterate(d.searchQuery)].map((char, i, arr) => {
                            if(char.indexOf(' ') >= 0 && arr[i+1]) {
                                arr[i+1] = arr[i+1].toUpperCase();
                            }

                            return char;
                        })
                        let id = transliterated.join('').replaceAll(/[^a-zA-Z0-9]/g, '');

                        let nextOption = {
                            id,
                            key: id,
                            value: d.searchQuery,
                            text: d.searchQuery,
                            onClick: (e, d) => props.setSelected(d)
                        };

                        setSearchList([nextOption]);
                    } catch (e) {
                        // console.log(e);
                    }
                }}
            />
        </div>
    );
}

export default SearchMaterials;
