import React, {useEffect, useState} from 'react';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import Tab from './components/Tab';
import {fetchStaffList} from "./request/fetchStaffList";
import {removeStaff} from "./request/removeStaff";
import {fetchEnvironment} from "./request/fetchEnvironment";
import {fetchOrderList} from "./request/fetchOrderList";
import SearchSeries from "./vievs/SearchSeries";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [loggedStaff, setLoggedStaff] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [photo, setPhoto] = useState(undefined);
  const [orderList, setOrderList] = useState({});
  const [prevOrderList, setPrevOrderList] = useState({});
  const [staffList, setStaffList] = useState({});
  const [environment, setEnvironment] = useState({});
  const [staffSearchList, setSearchStaffList] = useState([{key: undefined, id: undefined, value: 'не обрано', text: 'не обрано'}]);
  const handleStaffList = async (items) => {
      if(Object.keys(items).length > 0) {
          let SearchStaffList = (Object.values(items).map(staff => {
              // console.log('staff', staff);
              return {key: staff.id, id: staff.id, value: `${staff.lastName} ${staff.firstName}`, text: `${staff.lastName} ${staff.firstName}`, email: staff.email, isAdmin: staff.isAdmin}
          }));

          SearchStaffList.sort((a, b) => {
              // console.log(a.value);
              if (a.value > b.value) {
                  return 1;
              }
              if (a.value < b.value) {
                  return -1;
              }
              return 0;
          })

          await setSearchStaffList(staffSearchList.concat(SearchStaffList));
      } else {
          items = {};
      }

      setStaffList(items)
  }
  const deleteStaff = async id => {
      await removeStaff({action: "removeStaff", staffId: id});
      await fetchStaffList().then(async items =>  await handleStaffList(items));
  }
  const trustedUsers = {}
  const setSelectedSeries = async (selectedSeries) => {
      console.log(selectedSeries);
        // console.log('selectedSeries', selectedSeries);
        // console.log(props.environment, props.setEnvironment);
        // props.environment.series = selectedSeries[0];
        // props.environment.year = selectedSeries[1];
        // lessons.series = selectedSeries[0];
        // lessons.year = selectedSeries[1];
        // console.log('lessons', lessons);
        // props.setEnvironment({...props.environment});
        // setLessons(lessons);

        // console.log(props);

        // let nextOrder = {...props.order};
        // nextOrder.staff = {...selectedStaff[0]};
        // nextOrder.selectedCellNumberId = selectedCellNumberId;
        // nextOrder.selectedServiceId = deliveryService.id;

        // Object.values(props.orderList).forEach(item => {
        //     if(
        //         item.staff.id === props.order.staff.id
        //         &&
        //         item.lessons.series.currentSeries.key === props.environment.series.currentSeries.key
        //         &&
        //         item.lessons.series.season[0] === props.environment.series.season[0]
        //     ) {
        //         nextOrder = {
        //             ...nextOrder,
        //             id: item.id,
        //             lessons: {...item.lessons},
        //             materials: {...item.materials},
        //             notes: item.notes,
        //             doneTime: item.doneTime,
        //             photoOrdered: {...item?.photoOrdered},
        //         }
        //     }
        // })
        //
        // props.setOrder(nextOrder);
    };

  useEffect(() => {
        let mounted = true;
        fetchStaffList()
            .then(async items => {
                if(mounted) {
                    // console.log(items);
                    // console.log(JSON.stringify(items, ' ', 4));
                    await handleStaffList(items);
                }
            })
        return () => mounted = false;
    }, []); //todo: execute it ONLY after auth

  useEffect(() => {
        let mounted = true;
        fetchEnvironment()
            .then(items => {
                // console.log(items);
                if(mounted) {
                    const getSeason = d => Math.floor((d / 12 * 4)) % 4 >= 3 ? -1 : Math.floor((d / 12 * 4)) % 4;
                    const getSeries = (currentYear, baseYear) => Math.floor((currentYear - baseYear)) % 3;
                    const currentDate = new Date();
                    // const currentDate = new Date("2023-11-12T23:50:21.817Z");
                    const seasonIndex = getSeason(currentDate.getMonth()) + 1;
                    let year = seasonIndex === 0 ? currentDate.getUTCFullYear() + 1 : currentDate.getUTCFullYear();
                    let series = {
                        season: items.season[seasonIndex],
                        currentSeries: items.series[getSeries(year, items.baseYear)]
                    };
                    let lessons = {
                        year,
                        series,
                        level: items.level,
                        lang: items.languages,
                        order: {},
                        current: {
                            year,
                            series,
                            level: items.level,
                            lang: items.languages,
                            order: {}
                        }
                    }

                    setEnvironment(lessons);
                }
            })
        return () => mounted = false;
    }, []) //todo: execute it ONLY after auth

  useEffect(() => {
        let mounted = true;

        fetchOrderList()
            .then(items => {
                if(mounted) {
                    setOrderList(items);
                    setPrevOrderList(items);
                }
            })

        return () => mounted = false;
    }, [])  //todo: execute it ONLY after auth

    // console.log('App -> render', Object.values(orderList).length);

  return (
    <div className="App" style={{height: window.innerHeight}}>
        <div>
            {isLogged
                ? <div>
                    <div style={{
                        display: "flex",
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}>
                            <div style={{margin: 30, fontWeight: "bold", fontSize: 40}}>Замовлення</div>
                            <div style={{margin: 30}}>
                                <SearchSeries
                                    orderList={orderList}
                                    environment={environment} setEnvironment={setEnvironment}
                                />
                            </div>
                        </div>
                        <div style={{margin: 30, fontWeight: "bold", fontSize: 14}}>Вітаю, {loggedStaff.name}</div>
                    </div>
                    <Tab
                        activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                        photo={photo} setPhoto={setPhoto}
                        orderList={orderList} setOrderList={setOrderList}
                        staffList={staffList} setStaffList={setStaffList}
                        loggedStaff={loggedStaff}
                        staffSearchList={staffSearchList} setSearchStaffList={setSearchStaffList}
                        deleteStaff={deleteStaff}
                        environment={environment} setEnvironment={setEnvironment}
                    />
                </div>
                : <div style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: window.innerHeight,
                }}>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            // console.log(credentialResponse.credential);
                            let decoded = jwt_decode(credentialResponse.credential);
                            let loggedStaff = Object.values(staffSearchList).filter(staff => {

                                 // console.log(decoded, staff.email, decoded.email, staff.isAdmin);
                                return staff.email === decoded.email && staff.isAdmin
                            })

                            if(loggedStaff.length > 0) {
                                setLoggedStaff(decoded);
                                setIsLogged(true);
                            }
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>
            }
        </div>
    </div>
  );
}

export default App;
