import React, {useEffect, useState} from 'react';
import { Button, Loader as SemanticLoader, Dimmer, Segment } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import StaffCard from './StaffCard';
import LessonsCard from './LessonsCard';
import MaterialsCard from './MaterialsCard';
import {fetchStaffList} from "../request/fetchStaffList";
import {uploadStaffList} from "../request/uploadStaffList";
import {uploadOrder} from "../request/uploadOrder";
import {fromCreateStaffEvent} from "../domain/Staff";
import { Loader } from "@googlemaps/js-api-loader";

const getCityLocation = async (city) => {
    const loader = new Loader({
        apiKey: "AIzaSyD0CoH32EDpVNWWlxs1qV_F7nEHGE18gZE",
        version: "weekly",
    });

    return await Promise.all([loader.load().then(async google => {
        let geocoder = new google.maps.Geocoder();

        let latlng = await geocoder.geocode({address: city}).then(result => {
            return result.results.map(item => {
                return {
                    lat: item.geometry.location.lat(),
                    lng: item.geometry.location.lng(),
                }})[0];
        });

        return latlng;
    })]);
};
const mockStaff = {
    id: '3765bc19-c393-40fd-a4f7-f2d8e6f4c4f3',
    firstName: 'Павло',
    middleName: 'Леонідович',
    lastName: 'Кулаковський',
    cellNumbers: {
        'e505e976-9612-47c6-ad6c-b3c0e0555db3': {
            id: 'e505e976-9612-47c6-ad6c-b3c0e0555db3',
            number: '+380935773234',
            isDefault: true
        },
        'c09df1ad-aa23-49a3-9ba0-12054a87d7d8': {
            id: 'c09df1ad-aa23-49a3-9ba0-12054a87d7d8',
            number: '+380964189292',
            isDefault: false
        }
    },
    selectedCellNumberId: 'e505e976-9612-47c6-ad6c-b3c0e0555db3',
    deliveryServices: {
        '5f4b9b09-7b22-4918-95ea-a903b431c4a2': {
            id: '5f4b9b09-7b22-4918-95ea-a903b431c4a2',
            receiver: {
                firstName: 'Павло',
                middleName: 'Леонідович',
                lastName: 'Кулаковський',
                cellNumber: '+380935773234'
            },
            name: 'Нова Пошта',
            city: 'Київ',
            region: 'Київська',
            department: 42,
            isDefaultService: true
        },
        '2d66f6ff-5906-440c-9f56-a9417037e62e': {
            id: '2d66f6ff-5906-440c-9f56-a9417037e62e',
            receiver: {
                firstName: '',
                middleName: '',
                lastName: '',
                cellNumber: ''
            },
            name: 'Укр Пошта',
            city: 'Київ',
            region: 'Київська',
            department: '03190',
            isDefaultService: false
        }
    },
    selectedServiceId: '5f4b9b09-7b22-4918-95ea-a903b431c4a2',
};
const mockEmptyStaff = {
    id: uuidv4(),
    firstName: '',
    lastName: '',
    middleName: '',
    cellNumbers: {},
    deliveryServices: {},
    selectedCellNumberId: '',
    selectedServiceId: ''
};
function MockEmptyStaff() {
    this.id = uuidv4();
    this.firstName = '';
    this.lastName = '';
    this.middleName = '';
    this.cellNumbers = {};
    this.email = '';
    this.isAdmin = '';
    this.deliveryServices = {};
    this.selectedCellNumberId = '';
    this.selectedServiceId = '';
}
function MockEmptyOrder() {
    this.id = uuidv4();
    this.staff = {};
    this.lessons = {};
    this.materials = [];
    this.notes = '';
    this.doneTime = '';
}

function NewOrder(props) {
    const [staffList, setStaffList] = useState({...props.staffList});
    const [staffSearchList, setSearchStaffList] = useState(props.staffSearchList);
    const [selectedStaff, setSelectedStaff] = useState({... new MockEmptyStaff()});
    const [isForm, setIsForm] = useState(true); //todo: implement to check form data
    const [order, setOrder] = useState({... new MockEmptyOrder()});
    const [formErrors, setFormErrors] = useState({});
    const [spinner, setSpinner] = useState(false);
    const styles = {
        newOrder: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flexWrap: "wrap",
        },
        zoomImageContainer: {
            height: window.innerHeight * 40 / 100,
            width: window.innerWidth * 95 / 100,
            overflow: "scroll",
            border: "1px solid"
        },
        newOrderContainer: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            // flexWrap: "wrap",
            marginRight: 10,
            marginBottom: 10,
            height: window.innerHeight * 50 / 100,
            width: "100%",
            // border: "1px solid"
            backgroundColor: "#f6f4f4",
            overflow: "scroll",
        },
        submitButton: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            width: "100%",
        },
    };
    const checkForm = () => {
        let nextFormErrors = {...formErrors}

        try {
            if(Object.keys(order.staff).length === 0) nextFormErrors = {...nextFormErrors, isLastName: {status: false, text: 'вкажіть призвище', deFaultText: 'вкажіть призвище'}, isFirstName: {status: false, text: 'вкажіть ім\'я', deFaultText: 'вкажіть ім\'я'}};
            if(Object.keys(order.staff.firstName).length === 0) nextFormErrors = {...nextFormErrors, isLastName: {status: false, text: 'вкажіть призвище', deFaultText: 'вкажіть призвище'}};
            if(Object.keys(order.staff.lastName).length === 0) nextFormErrors = {...nextFormErrors, isFirstName: {status: false, text: 'вкажіть ім\'я', deFaultText: 'вкажіть ім\'я'}};
            if(Object.keys(order.staff.cellNumbers).length === 0) nextFormErrors = {...nextFormErrors, isCellNumber: {status: false, text: 'вкажіть номер телефону', deFaultText: 'вкажіть номер телефону'}};
            if(Object.values(order.staff.cellNumbers).filter(cellNumber => cellNumber.isDefault).length === 0) nextFormErrors = {...nextFormErrors, isDefaultCellNumber: {status: false, text: 'оберіть номер телефону за замовченням', deFaultText: 'оберіть номер телефону за замовченням'}};
            if(Object.keys(order.staff.deliveryServices).length === 0) nextFormErrors = {...nextFormErrors, isDeliveryService: {status: false, text: 'оберіть службу доставки', deFaultText: 'оберіть службу доставки'}};
            if(Object.values(order.staff.deliveryServices).filter(deliveryService => deliveryService.isDefaultService).length === 0) nextFormErrors = {...nextFormErrors, isDefaultService: {status: false, text: 'оберіть службу доставки за замовченням', deFaultText: 'оберіть службу доставки за замовченням'}};
        } catch (e) {}

        setFormErrors({...nextFormErrors});

        let isFormHasError = Object.values(nextFormErrors).reduce((accumulator, error) => accumulator + error.text.length, 0);

        setIsForm(isFormHasError === 0);

        return isFormHasError === 0;
    };
    const storeOrder = async () => {
        if(!checkForm()) return;

        let nextDeliveryService = await Object.values(order.staff.deliveryServices).map(async deliveryService => {
            if(!await deliveryService.latlng && deliveryService.city) {
                console.log('fetch staff location', deliveryService.city);
                let latlng = await getCityLocation(deliveryService.city);
                deliveryService["latlng"] = latlng[0];
            }
            return deliveryService;
        })

        await Promise.all(nextDeliveryService).then(async nextDeliveryService => {
            order.staff.deliveryServices[nextDeliveryService.id] = nextDeliveryService;
            let nextStaff = {...order.staff};

            delete nextStaff.deliveryServices.undefined;

            // /*
            if(!staffList.hasOwnProperty(nextStaff.id)) nextStaff = fromCreateStaffEvent(nextStaff);

            staffList[nextStaff.id] = nextStaff;

            let storedOrder = {
                id: order.id,
                staff: {
                    id: nextStaff.id,
                    firstName: nextStaff.firstName,
                    lastName: nextStaff.lastName,
                    middleName: nextStaff.middleName,
                    cellNumber: nextStaff.cellNumbers[order.selectedCellNumberId].number,
                    email: nextStaff.email,
                    isAdmin: nextStaff.isAdmin,
                    deliveryService: nextStaff.deliveryServices[order.selectedServiceId],
                },
                lessons: order.lessons,
                materials: order.materials,
                photoOrdered: props?.photo || order?.photoOrdered,
                notes: order.notes,
                doneTime: order.doneTime,
            };

            try{
                setSpinner(true);
                await uploadStaffList({action: 'saveStaffList', staffList});
            } catch (e) {}

            try{
                await uploadOrder({action: 'saveOrder', storedOrder});
            } catch (e) {}
            // */
        });

        await fetchStaffList()
            .then(items => {
                // console.log(items);
                if(Object.keys(items).length > 0) {
                    setSearchStaffList(Object.values(items).map(staff => {
                        // console.log(staff);
                        return {key: staff.id, id: staff.id, value: staff.lastName, text: staff.lastName}
                    }))
                } else {
                    items = {};
                }

                setStaffList(items);
                props.setStaffList(items);
                // setOrder({... new MockEmptyOrder()});
                // setSelectedStaff({... new MockEmptyStaff()});
                props?.setPhoto(undefined);
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSpinner(false);
                setOrder({... new MockEmptyOrder()});
                setSelectedStaff({...new MockEmptyStaff()});
            })
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // console.log(props?.photo?.photoUrl, order?.photoOrdered?.photoUrl);
    // console.log(props);

    return (
        <Segment>
            <Dimmer active={spinner}>
                <SemanticLoader />
            </Dimmer>

        <div className="PendingOrders" style={styles.newOrder}>
            {
                props?.photo?.photoUrl || order?.photoOrdered?.photoUrl
                    ? <div className={"zoom-image-container"} style={styles.zoomImageContainer}>
                        <img src={props?.photo?.photoUrl || order?.photoOrdered?.photoUrl} style={{transform: "scale(0.9, 0.9)"}}/>
                      </div>
                    : ''
            }
            <div className={'new-order-container'} style={styles.newOrderContainer} >
                <StaffCard
                    staffList={staffList}
                    order={order} setOrder={setOrder}
                    staffSearchList={staffSearchList}
                    selectedStaff={selectedStaff} setSelectedStaff={setSelectedStaff}
                    formErrors={formErrors} setFormErrors={setFormErrors}
                    photo={props.photo}
                    orderList={props.orderList} setOrderList={props.setOrderList}
                    deleteStaff={props.deleteStaff}
                    environment={props.environment}
                />
                <LessonsCard
                    order={order} setOrder={setOrder}
                    orderList={props.orderList} setOrderList={props.setOrderList}
                    environment={props.environment} setEnvironment={props.setEnvironment}
                />
                <MaterialsCard
                    order={order} setOrder={setOrder}
                    orderList={props.orderList} setOrderList={props.setOrderList}
                />
                <div className={'submit-button'} style={styles.submitButton}>
                    <Button basic color={'black'} content='Створити' onClick={storeOrder}/>
                    {/*<Button disabled={!isForm} basic color={isForm ? 'black' : 'red'} content='Створити' onClick={storeOrder}/>*/}
                </div>
            </div>
        </div>
        </Segment>
    );
}

export default NewOrder;
