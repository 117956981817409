import React, {useEffect, useState} from 'react';
import {Loader, Segment, Dimmer, Table} from 'semantic-ui-react';
import {fetchOrderList} from "../request/fetchOrderList";

const styles = {
    newOrderContainer: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
    printAllOrders: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexWrap: "wrap",
        width: '100%',
        marginBottom: 20,
    },
    newOrder: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
        width: '100%',
    },
    newOrderHeader: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        flexWrap: "wrap",
        width: '100%',
    },
    orderList: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
        // backgroundColor: '#ded7d7',
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 90 / 100,
    },
    temporaryPreviewPdf: {
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 95 / 100,
    },
    orderContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: "wrap",
        marginRight: 20
    },
    orderName: {
        marginRight: 10
    },
    totalBarContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
};

function Remind(props) {
    const [orderList, setOrderList] = useState({});
    const [environment, setEnvironment] = useState({...props.environment});
    const allSeries =  useState(Object.values(props.orderList).reduce((accumulator, currentValue) => accumulator.add(
        currentValue.lessons.series.currentSeries.value + " " +
        currentValue.lessons.series.season[0] + " " +
        currentValue.lessons.year
        ),
        new Set()
    ));
    const allStaff = useState(Object.values(props.orderList).reduce((accumulator, currentValue) => {
            let staff = new Array(allSeries[0].size + 4);
            let series = currentValue.lessons.series.currentSeries.value + " " + currentValue.lessons.series.season[0] + " " + currentValue.lessons.year;
            let indexSeries = Array.from(allSeries[0]).indexOf(series);
            let orderAmount = Object.values(currentValue.lessons.order).reduce((a, c) => a + Math.floor(c.value), 0)

            if(accumulator.has(currentValue.staff.id)) staff = accumulator.get(currentValue.staff.id)

            staff[0] = currentValue.staff.lastName;
            staff[1] = currentValue.staff.firstName;
            staff[2] = currentValue.staff.cellNumber;
            staff[3] = currentValue.staff.deliveryService.city;

            if(indexSeries > -1) staff[indexSeries + 4] = {series, orderAmount}

            return accumulator.set(currentValue.staff.id, staff)
        },
        new Map()
    ));

    useEffect(() => {
        let mounted = true;
        fetchOrderList()
            .then(items => {
                if(mounted) {
                    setOrderList(items)
                    props.setOrderList({...items});
                }
            })

        return () => mounted = false;
    }, [])
    useEffect(() => {
        let mounted = true;

        setEnvironment(props.environment);

        return () => mounted = false;
    }, [props.environment])

    if(Object.keys(orderList).length === 0) return <Segment style={{height: window.innerHeight}}><Dimmer active={Object.keys(orderList).length === 0}><Loader /></Dimmer></Segment>;

    return (
        <div className={'new-order-container'} style={styles.newOrderContainer}>
            <div className="order-list-container" style={styles.newOrder}>
                <Table celled structured striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Інструктор</Table.HeaderCell>
                            {
                                Array.from(allSeries[0])
                                    .map(series => <Table.HeaderCell key={series} colSpan={allSeries[0].size} textAlign='center'>{series}</Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {

                            Array.from(allStaff[0])
                                .filter(staff => staff[1][0].length > 1)
                                .sort((a, b) => {
                                    // console.log(a[1].length);
                                    // console.log(a[1][a[1].length - 2]?.orderAmount, b[1][b[1].length - 2]?.orderAmount);
                                    let isOrderPrevPeriodA = typeof a[1][a[1].length - 2] === "object"
                                        ? 1
                                        : 0;
                                    let isOrderPrevPeriodB = typeof b[1][b[1].length - 2] === "object"
                                        ? 1
                                        : 0;

                                    if (isOrderPrevPeriodA > isOrderPrevPeriodB) {
                                        return -1;
                                    }
                                    if (isOrderPrevPeriodA < isOrderPrevPeriodB) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                // .sort((a, b) => {
                                //     // console.log(a[1].length);
                                //     // console.log(a[1][a[1].length - 2]?.orderAmount, b[1][b[1].length - 2]?.orderAmount);
                                //     let isOrderPrevPeriodA = typeof a[1][a[1].length - 3] === "object"
                                //         ? 1
                                //         : 0;
                                //     let isOrderPrevPeriodB = typeof b[1][b[1].length - 3] === "object"
                                //         ? 1
                                //         : 0;
                                //
                                //     if (isOrderPrevPeriodA > isOrderPrevPeriodB) {
                                //         return -1;
                                //     }
                                //     if (isOrderPrevPeriodA < isOrderPrevPeriodB) {
                                //         return 1;
                                //     }
                                //     return 0;
                                // })
                                // .sort((a, b) => {
                                //     if (a[1][0] > b[1][0]) {
                                //         return 1;
                                //     }
                                //     if (a[1][0] < b[1][0]) {
                                //         return -1;
                                //     }
                                //     return 0;
                                // })
                                .map((staff, i, orderedList) => {
                                    let message = 'viber';

                                    return <Table.Row key={`${staff[0]}`}>
                                        <Table.HeaderCell textAlign='left' style={{paddingLeft: 20}}>{`
                                            ${i+1}
                                            ${staff[1][0]} 
                                            ${staff[1][1]} 
                                            ${staff[1][2]} 
                                            ${staff[1][3]} 
                                            `}
                                            <a
                                                href={"viber://chat?number=" + staff[1][2].replace("+", "")}
                                                style={{fontWeight: "bold", color: "#745fcf"}}
                                                onClick={() => navigator.clipboard.writeText(`Вітаю, ${staff[1][1]}! Наразі триває замовлення дитячих уроків "Біблійна Година" на ${environment.current.series.season[0]} ${environment.year}р. Очікуємо на Ваше замовлення!`)}
                                            >{message}</a>
                                        </Table.HeaderCell>
                                        {
                                            Array.from(allSeries[0]).map((e, i) => {
                                                let order = staff[1].filter(i => i.series === e ? i : "");

                                                return <Table.HeaderCell key={`${staff[0]}_${i}`} style={{paddingTop: 10, paddingBottom: 10}} colSpan={allSeries[0].size} textAlign='center'>{order[0] ? order[0].orderAmount : ""}</Table.HeaderCell>
                                            })
                                        }
                                    </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

export default Remind;
