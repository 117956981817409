import React, {useEffect, useState} from 'react';
import { Table, Input, Loader, TextArea } from 'semantic-ui-react';
import {fetchMaterials} from "../request/fetchMaterials";
import SearchMaterials from './SearchMaterials';

function MaterialsCard(props) { //todo: update materials form with completed order
    const styles = {
        materialsCardCard: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flexWrap: "wrap",
            // backgroundColor: 'yellow',
            width: '100%',
            marginTop: 30,
            marginBottom: 150,
        },
        tableNameColumn: {
            width: "60%"
        }
    };
    const [order, setOrder] = useState({...props.order});
    const [materials, setMaterials] = useState([]);
    const [orderedMaterials, setOrderedMaterials] = useState({});
    // const [orderedMaterials, setOrderedMaterials] = useState({ru: {}, ua:{}});
    const [selectedMaterials, setSelectedMaterials] = useState([]);

    const setSelected = (item) => {
        let nextSetSelectedMaterials = [...selectedMaterials];

        if(nextSetSelectedMaterials.filter(material => material.id === item.id).length === 0) {
            nextSetSelectedMaterials.push({
                key: item.id,
                id: item.id,
                value: item.text,
                text: item.text,
            });

            // console.log(nextSetSelectedMaterials);
            setSelectedMaterials(nextSetSelectedMaterials);
        }
    };
    const orderMaterials = (material) => {
        let metadata = material.tabIndex.split('_'); //`ru_id_text`
        let nextOrderedMaterials = {...orderedMaterials};
        // console.log(nextOrderedMaterials);

        nextOrderedMaterials[metadata[1]] = {
            id: metadata[1],
            text: metadata[2],
            amount: {
                ...nextOrderedMaterials[metadata[1]]?.amount,
                [metadata[0]]: material.value,
            },
        }

        // console.log(nextOrderedMaterials);

        let nextOrder = {...props.order, "materials": {...props.order.materials, ...nextOrderedMaterials}};
        props.setOrder(nextOrder);

        setOrderedMaterials(nextOrderedMaterials);
    };

    useEffect(() => {
        let mounted = true;
        fetchMaterials()
            .then((items) => {
                if(mounted) {
                    setMaterials(items);
                }
            })
        return () => mounted = false;
    }, [])

    useEffect(() => {
        // console.log('useEffect', order.staff?.id, props.order.staff?.id);
        if(order.staff?.id !== props.order.staff?.id) {
            // console.log('useEffect updated!');
            // console.log('useEffect', props.order);
            let nextSetSelectedMaterials = Object.values(props.order.materials).map(material => {
                return {
                    key: material.id,
                    id: material.id,
                    value: material.text,
                    text: material.text,
                }
            })
            // console.log('useEffect', nextSetSelectedMaterials);

            setSelectedMaterials(nextSetSelectedMaterials);
            setOrder(props.order);
        }
        // setStaff(props.selectedStaff);

    }, [props.order])


    const getMaterialValue = ((materialId, lang) => {
        return props?.order?.materials[materialId]?.amount[lang];
    })

    let storedMaterials = props?.order?.materials ? Object.values(props?.order?.materials) : undefined;

    if(materials.length < 1) return <Loader active inline />;

    // console.log(storedMaterials);
    // console.log('RENDER', props.order, order);

    return (
        <div className={'materials-card'} style={styles.materialsCardCard}>
            <h4>Допоміжні матеріали</h4>
            <Table celled structured>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' style={styles.tableNameColumn} >Назва</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Рос</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Укр</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    <Table.Row>
                        <Table.Cell textAlign='center'>
                            <SearchMaterials
                                selectedMaterials={selectedMaterials}
                                setSelectedMaterials={setSelectedMaterials}
                                materials={materials}
                                setSelected={setSelected}
                            />
                        </Table.Cell>
                    </Table.Row>

                    {
                        selectedMaterials.map(material => {
                            // console.log(material);
                            return(
                                <Table.Row key={material.text}>
                                    <Table.Cell textAlign='left'>{material.text}</Table.Cell>
                                    <Table.Cell textAlign='left'><Input value={getMaterialValue(material.id, 'ru')} tabIndex={`ru_${material.id}_${material.text}`} fluid size={'mini'} onChange={(e, d) => orderMaterials(d)} /></Table.Cell>
                                    <Table.Cell textAlign='left'><Input value={getMaterialValue(material.id, 'ua')} tabIndex={`ua_${material.id}_${material.text}`} fluid size={'mini'} onChange={(e, d) => orderMaterials(d)} /></Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
            <h4>Нотатки</h4>
            <TextArea
                placeholder='Нотатки'
                style={{width: '100%'}}
                rows={7}
                value={props.order.notes}
                onChange={(e, d) => {
                    let nextOrder = {...props.order, notes: d.value};
                    props.setOrder(nextOrder);
                }}
            />
        </div>
    );
}

export default MaterialsCard;
