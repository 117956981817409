import React from 'react';
import { Tab as TabSemantic } from 'semantic-ui-react';
import PendingOrders from '../vievs/PendingOrders';
import NewOrder from '../vievs/NewOrder';
import OrderList from '../vievs/OrderList';
import Report from '../vievs/Report';
import Remind from '../vievs/Remind';
import StaffMap  from '../vievs/StaffMap';

const panes = (props) => {
    // console.log('const panes = (props) => {', props);
    return [
        { menuItem: 'Нові', render: () => <TabSemantic.Pane><PendingOrders
                setPhoto={props.setPhoto}
                photo={props.photo}
                setActiveIndex={props.setActiveIndex}
            /></TabSemantic.Pane> },
        { menuItem: 'Створити', render: () =>
            <TabSemantic.Pane>
                <NewOrder
                    photo={props.photo} setPhoto={props.setPhoto}
                    loggedStaff={props.loggedStaff}
                    orderList={props.orderList} setOrderList={props.setOrderList}
                    staffList={props.staffList} setStaffList={props.setStaffList}
                    staffSearchList={props.staffSearchList} setSearchStaffList={props.setSearchStaffList}
                    deleteStaff={props.deleteStaff}
                    environment={props.environment} setEnvironment={props.setEnvironment}
                />
            </TabSemantic.Pane> },
        { menuItem: 'Нагадати', render: () => <TabSemantic.Pane>
                <Remind orderList={props.orderList} setOrderList={props.setOrderList} environment={props.environment} />
            </TabSemantic.Pane> },
        { menuItem: 'Перелік', render: () =>
            <TabSemantic.Pane>
                <OrderList orderList={props.orderList} setOrderList={props.setOrderList} environment={props.environment} />
            </TabSemantic.Pane> },
        { menuItem: 'Звіт', render: () => <TabSemantic.Pane>
                <Report orderList={props.orderList} setOrderList={props.setOrderList} environment={props.environment} />
        </TabSemantic.Pane> },
        { menuItem: 'Локації інструкторів', render: () => <TabSemantic.Pane>
                <StaffMap staffList={props.staffList} setStaffList={props.setStaffList} />
        </TabSemantic.Pane> },
    ]};
const Tab = (props) => <TabSemantic
    activeIndex={props.activeIndex}
    panes={panes(props)}
    onTabChange={(e, d) => {
        props.setPhoto(props.photo)
        props.setActiveIndex(d.activeIndex)}
    }
/>

export default Tab
