import React, {useEffect, useState} from 'react';
import {Button, Loader, Icon, Segment, Dimmer, Header, Modal, Table, Checkbox, Form} from 'semantic-ui-react';
import {fetchOrderList} from "../request/fetchOrderList";
import { jsPDF } from "jspdf";
import {AmiriRegularTTF} from '../components/Amiri-Regular_ttf'

const styles = {
    newOrderContainer: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
    printAllOrders: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexWrap: "wrap",
        width: '100%',
        marginBottom: 20,
    },
    newOrder: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
        width: '100%',
    },
    newOrderHeader: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        flexWrap: "wrap",
        width: '100%',
    },
    orderList: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: "wrap",
        // backgroundColor: '#ded7d7',
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 90 / 100,
    },
    temporaryPreviewPdf: {
        width: window.innerWidth * 90 / 100,
        height: window.innerHeight * 95 / 100,
    },
    orderContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: "wrap",
        marginRight: 20
    },
    orderName: {
        marginRight: 10
    },
    totalBarContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexWrap: "wrap",
    },
};

function Report(props) {
    const [open, setOpen] = React.useState(false)
    const [orderList, setOrderList] = useState({});
    const [order, setOrder] = useState({});
    const [isSpinner, setIsSpinner] = useState(false);
    const [environment, setEnvironment] = useState({...props.environment});
    const [showAllOrders, setShowAllOrders] = useState(true);
    const [totalRowLessons, setTotalRowLessons] = useState({});
    const [cellsInTotalRow, setCellsInTotalRow] = useState([]);
    const totalLessons = {};
    const numSeries =  useState([...new Set(Object.values(props.orderList).map(order => [
        order.lessons.series,
        order.lessons.year,
    ]).map(JSON.stringify))].map(JSON.parse).length)[0];
    const totalMaterials = {};
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts:true
    });
    doc.addFileToVFS("Amiri-Regular.ttf", AmiriRegularTTF);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri");

    const createOrdersPdf = () => {
        Object.values(orderList).reverse().map((order, i) => {
            if(i % 2 || i === 1) {
                createOrderPdf(order.id, 148)
                if(i !== 0) doc.addPage();
            } else {
                createOrderPdf(order.id, 0)
            }
        })
    }
    const createOrderPdf = (orderId, shift) => {
        let order = orderList[orderId];
        let setX = (x) => x + shift;

        doc.setFontSize(14);
        doc.line(setX(148), 2, setX(148), 208, "DF")
        doc.line(setX(95), 2, setX(146), 45, "DF")
        doc.text(`${order.staff.deliveryService?.receiver?.firstName || 'Error: undefined'}`, setX(130),7, null, -42);
        doc.text(`${order.staff.deliveryService?.receiver?.lastName || 'Error: undefined'}`, setX(120), 8, null, -42);
        doc.setFontSize(18);
        doc.text(`${order.staff.deliveryService.name}`, setX(110),10, null, -42);
        doc.setFontSize(24);
        doc.text(`${order?.lessons?.series?.season[0] || ''}`, setX(5), 13);
        doc.text(`${order?.lessons?.year || ''} серія ${order?.lessons?.series?.currentSeries?.value || ''}`, setX(32), 13);
        doc.setFontSize(14);
        doc.text(`${order.staff.lastName}`, setX(10), 23);
        doc.text(`${order.staff.firstName}`, setX(10),31);
        doc.text(`${order.staff.middleName}`, setX(10),39);
        doc.text(`${order.staff.cellNumber}`, setX(10),47);
        doc.line(setX(53), 20, setX(53), 50, "DF")
        doc.setFontSize(18);
        doc.text(`${order.staff.deliveryService.name}`, setX(60),25);
        doc.text(
            `${order.staff.deliveryService.city } ${order.staff.deliveryService.department}`,
            setX(60),35
        );
        doc.text(`обл. ${order.staff.deliveryService.region }`, setX(60),45);
        doc.text(`Отримувач:`, setX(8),58);
        doc.line(setX(6), 60, setX(77), 60, "DF")
        doc.setFillColor(222, 215, 215);
        doc.rect(setX(6), 125, 140, 10, "F");
        doc.rect(setX(6), 145, 140, 10, "F");

        doc.setFontSize(14);
        doc.text(`${order.staff.deliveryService?.receiver?.lastName || 'Error: undefined'}`, setX(8),65);
        doc.text(`${order.staff.deliveryService?.receiver?.middleName || ''}`, setX(8),73);
        doc.text(`${order.staff.deliveryService?.receiver?.firstName || 'Error: undefined'}`, setX(50),65);
        doc.text(`${order.staff.deliveryService?.receiver?.cellNumber || 'Error: undefined'}`, setX(38),77);
        doc.rect(setX(81), 55, 30, 25, "D")
        doc.rect(setX(115), 55,30, 25, "D")
        doc.text(`Розмір:`, setX(83),60);
        doc.text(`Вага:`, setX(117),60);
        doc.setFontSize(24);
        doc.text(`Замовлення`, setX(50),90);
        doc.setFontSize(12);
        doc.line(setX(6), 95, setX(146), 95, "DF")
        doc.text(`Рівень`, setX(8),106);
        doc.text(`I`, setX(15),122);
        doc.text(`II`, setX(14),132);
        doc.text(`III`, setX(13),142);
        doc.text(`IV`, setX(14),152);
        doc.line(setX(6), 95, setX(6), 155, "DF")
        doc.text(`Рос`, setX(32),102);
        doc.text(`Укр`, setX(52),102);
        doc.line(setX(26), 95, setX(26), 155, "DF")
        doc.text(`Рос`, setX(72),102);
        doc.text(`Укр`, setX(92),102);
        doc.line(setX(46), 95, setX(46), 155, "DF")
        doc.text(`Рос`, setX(112),102);
        doc.text(`Укр`, setX(132),102);
        doc.line(setX(66), 95, setX(66), 155, "DF")
        doc.line(setX(126), 95, setX(126), 155, "DF")
        doc.line(setX(86), 95, setX(86), 155, "DF")
        doc.line(setX(106), 95, setX(106), 155, "DF")
        doc.line(setX(146), 95, setX(146), 155, "DF")

        let seriesRuStartX = 34;
        let seriesUaStartX = 54;
        let seriesShiftX = 0;
        for (let i = 1; i < order.lessons.series.season.length; i++) {
            doc.text(`${order.lessons.series.currentSeries.value}${order.lessons.series.season[i]}`,
                setX(seriesRuStartX + seriesShiftX),112);
            doc.text(`${order.lessons.series.currentSeries.value}${order.lessons.series.season[i]}`,
                setX(seriesUaStartX + seriesShiftX),112);

            seriesShiftX += 40;
        }

        let sortedOrder = Object.values(order?.lessons?.order)
            .sort((a, b) => {
                if (parseInt(a.cellId) > parseInt(b.cellId)) {
                    return 1;
                }
                if (parseInt(a.cellId) < parseInt(b.cellId)) {
                    return -1;
                }
                return 0;
            });

        let startX = 34;
        let startY = 122;
        let shiftX = 20;
        let shiftY = 10;
        let rowCellsCount = 6;

        sortedOrder.forEach((cell, i) => {
            let cellId = parseInt(cell.cellId);

            doc.text(cell.value, setX(startX) + (shiftX * (cellId - Math.floor(cellId / rowCellsCount) * rowCellsCount)), startY + (shiftY * Math.floor(cellId / rowCellsCount)));
        })

        doc.text(`${order?.lessons?.lessons?.monthKey[0] || ''}`, setX(34),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[0] || ''}`, setX(54),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[1] || ''}`, setX(74),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[1] || ''}`, setX(94),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[2] || ''}`, setX(114),111);
        doc.text(`${order?.lessons?.lessons?.monthKey[2] || ''}`, setX(134),111);

        doc.line(setX(26), 105, setX(146), 105, "DF")
        doc.line(setX(6), 115, setX(146), 115, "DF")
        doc.line(setX(6), 125, setX(146), 125, "DF")
        doc.line(setX(6), 135, setX(146), 135, "DF")
        doc.line(setX(6), 145, setX(146), 145, "DF")
        doc.line(setX(6), 155, setX(146), 155, "DF")
        doc.line(setX(6), 158, setX(146), 158, "DF")
        doc.text(`Назва`, setX(25),162);
        doc.text(`Назва`, setX(93),162);
        doc.text(`Рос`, setX(55),162);
        doc.text(`Рос`, setX(127),162);
        doc.text(`Укр`, setX(65),162);
        doc.text(`Укр`, setX(137),162);
        doc.line(setX(6), 164, setX(146), 164, "DF")
        doc.setFontSize(10);

        let X = 7;
        let Y = 164;
        let stepShift  = 4;
        let nextColumnShift  = 70;

        Object.values(order.materials).forEach((material, i) => {
            if(material.id) {
                if(i === 10) Y = 164;

                Y = Y + stepShift;

                if(i <= 9) {
                    doc.text(material.text.substring(0, 22), setX(X), Y);
                    doc.text(material.amount['ru']
                        ? material.amount['ru']
                        : ''
                        , setX(X+50), Y);
                    doc.text(material.amount['ua']
                        ? material.amount['ua']
                        : ''
                        , setX(X+60), Y);

                    if(i % 2) {
                    } else {
                        doc.setFillColor(222, 215, 215);
                        doc.rect(setX(X), Y+1, 67, 4, "F");
                    }

                } else {
                    doc.text(material.text.substring(0, 22), setX(X + nextColumnShift), Y);
                    doc.text(material.amount['ru']
                        ? material.amount['ru']
                        : ''
                        , setX(X+nextColumnShift+50), Y);
                    doc.text(material.amount['ua']
                        ? material.amount['ua']
                        : ''
                        , setX(X+nextColumnShift+60), Y);
                    if(i % 2) {
                    } else {
                        doc.setFillColor(222, 215, 215);
                        doc.rect(setX(X+nextColumnShift-3), Y+1, 71, 4, "F");
                    }
                }

            }
        })

        doc.line(setX(6), 158, setX(6), 205, "DF")
        doc.line(setX(54), 158, setX(54), 205, "DF")
        doc.line(setX(64), 158, setX(64), 205, "DF")
        doc.line(setX(74), 158, setX(74), 205, "DF")
        doc.line(setX(125), 158, setX(125), 205, "DF")
        doc.line(setX(136), 158, setX(136), 205, "DF")
        doc.line(setX(146), 158, setX(146), 205, "DF")

        setOrder(doc.output('bloburi', {filename: `${order.staff.lastName}.pdf`}))
        setOpen(true);
    }
    const getMaterials = () => {
        let totalMaterials = [];
        let langs = Object.values(orderList)[0].lessons.lang.map(lang => Object.keys(lang)[0]);
        let items = {};

        Object.values(orderList).map((order, i) => {
            if(Object.keys(order.materials).length === 0) return;

            Object.values(order.materials).map(material => {
                langs.map(lang => totalMaterials.push({
                    id: material.id,
                    text: material.text,
                    lang,
                    staff: order.staff.lastName + " " + order.staff.firstName,
                    [lang]: parseInt(material.amount[lang]) || 0,
                    num: parseInt(material.amount[lang]) || 0
                }))
            })
        })

        totalMaterials.forEach(material => {
           let {id, text, lang} = material;
           items[id] = {...items[id], text, [lang]: items[id] && items[id][lang] ? items[id][lang] + material[lang] : material[lang]}
        })

        return Object.values(items).map((item, i) => {
                    return <Table.Row key={`row_total_${i}`}>{Object.keys(item).map(key => {
                        return <Table.HeaderCell key={`total_${key}`} style={{paddingTop: 10, paddingBottom: 10}} textAlign='center'>
                            {item[key] === 0 ? "" : item[key]}
                        </Table.HeaderCell>
                    })}</Table.Row>
                })
    }
    const setTotalRowData = (totalLessons, cellsInRow) => {
        setTotalRowLessons(totalLessons);
        setCellsInTotalRow(cellsInRow);
    }

    let cellsInRow = [];

    const downloadFile = () => {
        const currentDate = new Date();

        const fileName = "orderList-" + currentDate.toString();
        const json = JSON.stringify(orderList, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    useEffect(() => {
        let mounted = true;
        fetchOrderList()
            .then(items => {
                if(mounted) {
                    setOrderList(items)
                    props.setOrderList({...items});
                }
            })

        return () => mounted = false;
    }, [])
    useEffect(() => {
        let mounted = true;

        setEnvironment(props.environment);

        return () => mounted = false;
    }, [props.environment])

    if(Object.keys(orderList).length === 0) return <Segment style={{height: window.innerHeight}}><Dimmer active={Object.keys(orderList).length === 0}><Loader /></Dimmer></Segment>;

    // console.log('Report -> render', environment.year,  environment.series.season[0]);

    return (
        <div className={'new-order-container'} style={styles.newOrderContainer}>

            <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size='fullscreen'
            >
                <Modal.Actions>
                    <Button color='green' inverted onClick={() => setOpen(false)}>Закрити</Button>
                </Modal.Actions>
                <Modal.Content>
                    <iframe src={order} height={styles.temporaryPreviewPdf.height} width={styles.temporaryPreviewPdf.width}/>
                </Modal.Content>
            </Modal>
            <div className={'print-all-orders'} style={styles.printAllOrders}>
                <Button basic color='black' content='Друкувати звіт' onClick={createOrdersPdf}/>
                <Button basic color='black' content='Зберегти всі замовлення' onClick={() => downloadFile()}/>
            </div>
            <div className="order-list-container" style={styles.newOrder}>
                <div className="order-list-container-header" style={styles.newOrderHeader}>
                    <h3>Курси</h3>
                    <Form.Field
                        id='showAllOrders'
                        control={Checkbox}
                        checked={showAllOrders}
                        label={`за весь період.`}
                        onChange={() => setShowAllOrders(!showAllOrders)}
                    />
                </div>
                <Table celled structured striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Інструктор</Table.HeaderCell>
                            <Table.HeaderCell colSpan='4' textAlign='center'>Укр</Table.HeaderCell>
                            <Table.HeaderCell colSpan='4' textAlign='center'>Рус</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>I</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>II</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>III</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>IV</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>I</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>II</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>III</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>IV</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center' style={{paddingTop: 10, paddingBottom: 10, color: '#FFFFFF', backgroundColor: '#7b7979'}}>
                                <div style={styles.totalBarContainer}>
                                    <div style={{marginRight: 20}}>Учнів {showAllOrders ? "(в середньому)" : ""}: {Math.round(Object.values(totalRowLessons).reduce((acc, cur) => acc + cur, 0) / 3 / (showAllOrders ? numSeries : 1))}</div>
                                    <div>Всього уроків: {Object.values(totalRowLessons).reduce((acc, cur) => acc + cur, 0)}</div>
                                </div>
                            </Table.HeaderCell>
                            {
                                cellsInTotalRow.map((e, i) => {
                                    return <Table.HeaderCell key={`total_${i}`} style={{paddingTop: 10, paddingBottom: 10, color: '#FFFFFF', backgroundColor: '#7b7979'}} textAlign='center'>
                                        {totalRowLessons[i + 1]}
                                    </Table.HeaderCell>
                                })
                            }
                        </Table.Row>
                        {
                            Object.values(orderList)
                                .filter(order => environment.series.season[0] === order.lessons.series.season[0] && environment.year === order.lessons.year || showAllOrders)
                                .sort((a, b) => {
                                    if (a.staff.lastName > b.staff.lastName) {
                                        return 1;
                                    }
                                    if (a.staff.lastName < b.staff.lastName) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                .map((order, i, orderedList) => {
                                    // console.log(orderedList[i].staff.lastName);
                                    // console.log(i, Object.values(orderList)[i+1]?.staff.id);
                                    cellsInRow = Array.from('x'.repeat(order.lessons.lang.length * order.lessons.level.length));
                                    let langs = [...order.lessons.lang].reverse();
                                    let cells = {};
                                    let message = '';
                                    let nextStaff = orderedList[i+1]?.staff;
                                    Object.values(order?.lessons?.order)
                                        .sort((a, b) => {
                                            if (parseInt(a.cellId) > parseInt(b.cellId)) {
                                                return 1;
                                            }
                                            if (parseInt(a.cellId) < parseInt(b.cellId)) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                        .map(lesson => {
                                            let langIndex = langs.findIndex(lang => lang[lesson.lang]);
                                            let lessonIndex = order.lessons.level.indexOf(lesson.level) + 1;
                                            let valueCellIndex = langIndex * 4 + lessonIndex;

                                            cells[valueCellIndex]
                                                ? cells[valueCellIndex] += parseInt(lesson.value) || 0
                                                : cells[valueCellIndex] = parseInt(lesson.value) || 0

                                            totalLessons[valueCellIndex]
                                                ? totalLessons[valueCellIndex] += parseInt(lesson.value) || 0
                                                : totalLessons[valueCellIndex] = parseInt(lesson.value) || 0

                                        })

                                    // console.log(order?.lessons.series.currentSeries.key, orderedList[i+1]?.lessons.series.currentSeries.key);

                                    if(order.staff.id !== nextStaff?.id && order?.lessons.series.currentSeries.key === orderedList[i+1]?.lessons.series.currentSeries.key) {
                                        message = 'viber';
                                    }

                                    return <Table.Row key={`${order.id}`}>
                                        <Table.HeaderCell textAlign='left' style={{paddingLeft: 20}}>{`
                                            ${i+1}
                                            ${order.staff.lastName} 
                                            ${order.staff.firstName} 
                                            ${order.staff.cellNumber}
                                            ${order.staff.deliveryService.city}
                                            ${showAllOrders ? "( " + order.lessons.series.season[0] + " " + order.lessons.year + " )" : ""}
                                            `}
                                            <a
                                                href={"viber://chat?number=" + order.staff.cellNumber.replace("+", "")}
                                                style={{fontWeight: "bold", color: "#745fcf"}}
                                                onClick={() => navigator.clipboard.writeText(`Вітаю, ${order.staff.firstName}! Наразі триває замовлення дитячих уроків "Біблійна Година" на ${environment.current.series.season[0]} ${environment.year}р. Очікуємо на Ваше замовлення!`)}
                                            >{message}</a>
                                        </Table.HeaderCell>
                                        {
                                            cellsInRow.map((e, i) => <Table.HeaderCell key={`${order.id}_${i}`} style={{paddingTop: 10, paddingBottom: 10}} textAlign='center'>{cells[i + 1]}</Table.HeaderCell>)
                                        }
                                    </Table.Row>
                            })
                        }
                        {
                            JSON.stringify(totalRowLessons) !== JSON.stringify(totalLessons) ? setTotalRowData(totalLessons, cellsInRow) : ""
                        }
                    </Table.Body>
                </Table>
                <h3>Додаткові матеріали</h3>
                <Table celled structured striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Матеріали</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Укр</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Рус</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            getMaterials()
                        }
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}

export default Report;
